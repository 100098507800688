import { PlayerPosition } from 'src/enums/player-position.enum';
import AfLogger from '@vendor/auf/afLogger';
export interface VideoLoggerOptions {
	context: VideoLoggerContext;
}

interface VideoLoggerContext {
	adPlayer: any;
	player: {
		version: string;
		name: string;
		videoId: string;
		context: any;
		position: PlayerPosition;
	};
}

enum VideoLogType {
	VIDEO_ERROR = 'VIDEO_ERROR',
	VIDEO_LOG = 'VIDEO_LOG'
}

const afLogger = new AfLogger({ disableSessionData: true });

export class VideoLogger {
	private serviceName = 'video';
	private sampleRate = 10;
	private noisyErrorSampleRate = 1;
	private noisyErrors = [
		'play() can only be initiated by a user gesture',
		'The request is not allowed by the user agent',
		'The play() request was interrupted by a call to pause()',
		"play() failed because the user didn't interact with the document first.",
		'The operation was aborted.', // Safari error due to browser settings or unmuted autoplay
		'The operation is not supported.' // Safari error that can be caused by lot of player errors
	];
	private logged: Map<string, Map<string, boolean>> = new Map<
		string,
		Map<string, boolean>
	>([
		[VideoLogType.VIDEO_ERROR, new Map()],
		[VideoLogType.VIDEO_LOG, new Map()]
	]);

	private sampled = this.sampleRate < Math.floor(Math.random() * 100);
	private context: any;
	private logger: any;

	constructor(options: VideoLoggerOptions) {
		this.initContext(options.context);
	}

	setVerbose = (isEnabled: boolean) =>
		afLogger.setVerbose(this.serviceName, isEnabled);

	logError(message: string, category: string, data: any) {
		const pool = `${this.serviceName} ${category ? ':' + category : ''}`;

		// Sampling to reduce cost & noise
		const errorRate = this.getErrorRate(message);
		if (errorRate && Math.floor(Math.random() * 100) > errorRate) {
			afLogger.logConsole('[' + pool + '] ' + message, 'error');
			return;
		}

		data = this.addContext({ ...data, sampleRate: errorRate });
		afLogger.logErrorWithContext(message, pool, data);
	}

	logEventOnce(message: string, videoData: any) {
		if (this.logged.get(VideoLogType.VIDEO_LOG)?.get(message)) return;

		this.logged.get(VideoLogType.VIDEO_LOG)?.set(message, true);
		this.logEvent(message, videoData);
	}

	logEvent = (
		message,
		videoData = {
			duration: undefined,
			file: undefined,
			position: undefined,
			url: undefined
		}
	) =>
		afLogger.logEvent(
			this.serviceName,
			message,
			this.addContext({
				sampled: this.sampled,
				sampleRate: this.sampleRate,
				video: {
					duration: videoData.duration,
					file: videoData.file,
					position: videoData.position,
					url: videoData.url
				}
			})
		);

	private initContext(context: VideoLoggerContext) {
		if (!context) return;

		this.context = context;
	}

	private getErrorRate = (message) =>
		this.noisyErrors.indexOf(message) > -1
			? this.noisyErrorSampleRate
			: this.sampleRate;

	private addContext = (data: any) => {
		const result = { ...data, ...this.context };
		return result;
	};
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.Tracker = window.AufVideo.Tracker || {};
window.AufVideo.Tracker.VideoLogger = VideoLogger;

import { distinctUntilChanged } from 'rxjs/operators';
import {
	PublishableVideoAdEvents,
	PublishableVideoContentEvents,
	PublishableVideoPlayerEvents,
	PublishableVideoStickyEvents,
	PublishableVideoStoreEvents
} from 'src/enums/publishable-events.enum';
import { StickyFormats } from 'src/enums/sticky-formats.enum';
import { ListenerAction } from 'src/interfaces/listener-action';
import {
	BannerStickyOptions,
	FlyingStickyOptions
} from 'src/interfaces/sticky-formats-options.interface';
import { PlayerInstance } from 'src/types/player-instance.type';
import { StickyFormatOptions } from 'src/types/stickyFormatOptions.type';
import { Store, StoreInstance } from '../../store';
import { FlyingStickyLayout } from './components/layouts/flying/flying.layout.component';
import { StickyButtonsEvents } from './enums/buttons-events.enum';
import { StickyLayoutEvents } from './types/sticky-events.type';
import { VideoConsoleLogger } from 'src/utils/video-logger';
import { BannerStickyLayout } from './components/layouts/banner/banner-layout.component';
import { formatSecondsToMinutes } from 'src/utils/time-utils.service';
import { fromEvent } from 'rxjs';
import {
	isStickySessionDisabled,
	setDisabledStickyForSessionState
} from './utils/session-storage.util';
import { getElementPosition, getWindowPosition } from './utils/dom.util';
import { applyCssTranslate } from './utils/translate.util';
import PubSub from 'pubsub-js';
import { Visibility } from './enums/visibility.enum';

const l = new VideoConsoleLogger();
export interface StickyPluginOptions {
	store: Store;
}

enum VP_TOP {
	IS_BELOW_CONTAINER_TOP = 'vp-top-below-top',
	IS_BELOW_CONTAINER_MIDDLE = 'vp-top-below-middle'
}

enum VP_BOTTOM {
	IS_ABOVE_CONTAINER_BOTTOM = 'vp-bottom-above-bottom',
	IS_ABOVE_CONTAINER_MIDDLE = 'vp-bottom-above-middle',
	IS_BELOW_CONTAINER_MIDDLE = 'vp-bottom-below-middle'
}

enum CONTAINER_POSITION {
	IS_PARTIALLY_WITHIN_VP = 'partially-within-vp',
	IS_WITHIN_VP = 'within-vp'
}

enum DEFAULT_SETTINGS {
	ONCOMPLETE_TIMEOUT = 11000,
	SCROLL_OFFSET = 200
}

type StickyLayout = FlyingStickyLayout | BannerStickyLayout;

export class StickyPlugin {
	public name: 'sticky';
	public scrollEvent: (...args: []) => any =
		this.stickMainViewablePlayer.bind(this);

	private _layout: StickyLayout;
	private _id: string;
	private _player: PlayerInstance;
	private _instance: StoreInstance;

	private _stickyFormat: StickyFormats;
	private _stickyFormatOptions: StickyFormatOptions;
	private _stickTo: string;
	private _disabledForPage = false;

	private _container: HTMLElement | Element;
	private _stickyRect: DOMRect | null;

	public enabled = false;
	private _closedByUser = false;
	private _onCompleteTimeout: ReturnType<typeof setTimeout> | null;
	private _isSticked = false;
	private _stickyReady = false;
	private _disabledByElementOnScreen = false;
	private _scrollUp: boolean;

	// Handle collisions of sticky player over floating elements (popup, ads)
	private _xPos: number | string = 0;
	private _yPos: number | string = 0;
	private _xPosExtra = 0;
	private _yPosExtra = 0;

	private _waitForAdLoaded = false;
	private _adLoaded = false;
	private _stickBothSide = false;

	private _store: Store;

	private screenOrientationChanged$ = fromEvent(screen.orientation, 'change');

	private _listenersActions: ListenerAction[] = [
		{
			// Once an instance is added to the store, register it
			message: PublishableVideoStoreEvents.ADD_INSTANCE,
			actionFn: (msg, instanceData) => {
				const instance = instanceData.instance;

				if (instance.id !== this._id) {
					l.debug('Message is not for this sticky plugin, skipping ...')();
					return;
				}

				// Do not register instance if sticky not enabled or an instance is already initialized
				if (!instance.sticky.stickTo || this._instance) {
					if (!instance.sticky.stickTo) {
						this.enabled = false;
					}

					return;
				}

				this._registerInstance(instance);
				this._initLayout(this._stickyFormat, this._stickyFormatOptions);
			}
		},
		{
			// Once a player is ready, init plugin
			message: PublishableVideoStickyEvents.STICKY_READY,
			actionFn: (msg, readyData: { id: string }) => {
				this._onPlayerReady.call(this, readyData.id);
			}
		},
		{
			message: PublishableVideoAdEvents.AD_LOADED,
			actionFn: () => {
				if (!this._waitForAdLoaded) return;

				this._adLoaded = true;
				this._onPlayerReady.call(this, this._id);
				this.stickMainViewablePlayer.call(this);
			}
		},
		{
			message: PublishableVideoAdEvents.AD_START,
			actionFn: () => {
				// Hide close button if layout is compatible and needed
				if (!this._stickyFormatOptions.closeBtnSwitchFeatureEnabled) return;
				this._layout.closeBtnVisibility$.next(Visibility.HIDDEN);
			}
		},
		{
			message: PublishableVideoContentEvents.VIDEO_START,
			actionFn: () => {
				// Hide close button if layout is compatible and needed
				if (!this._stickyFormatOptions.closeBtnSwitchFeatureEnabled) return;
				this._layout.closeBtnVisibility$.next(Visibility.VISIBLE);
			}
		},
		{
			message: PublishableVideoStickyEvents.STICKY_CONTENT_START,
			actionFn: () => {
				if (
					!this._stickyReady &&
					(!this._waitForAdLoaded || (this._waitForAdLoaded && !this._adLoaded))
				) {
					this._adLoaded = true;
					this._onPlayerReady(this._id, true);
					this.stickMainViewablePlayer();
				}

				if (!this._stickyReady || this._closedByUser) return;

				// If sticky already ready, re-enable sticky if not blocked permanently
				// to permit to relaunch the video player and get the sticky feature again
				this.enabled = !isStickySessionDisabled() && !this._disabledForPage;
				this._cancelDisablingTimeout();
			}
		},
		{
			message: PublishableVideoContentEvents.VIDEO_END,
			actionFn: () => {
				const _disableWithDelay = (timeout: number) => {
					// Set only one "unstick" timer (lock for concurrent calls)
					if (this._onCompleteTimeout) {
						return;
					}

					this._onCompleteTimeout = setTimeout(
						() =>
							typeof this._player.playlist === 'undefined'
								? this._disable(false, false, 'disableWithDelay')
								: null,
						timeout
					);
				};

				_disableWithDelay(DEFAULT_SETTINGS.ONCOMPLETE_TIMEOUT);
				// Unstick player after a few seconds when video is finished
			}
		},
		{
			message: PublishableVideoPlayerEvents.SEEKING,
			actionFn: this._cancelDisablingTimeout
		},
		{
			message: PublishableVideoPlayerEvents.PAUSE,
			actionFn: this._cancelDisablingTimeout
		},
		{
			message: 'mrtn-selection-box:open',
			actionFn: () => {
				const that = this;
				if (!this._stickyReady) return;

				onPopupShow(0, 110); // TODO: retrieve value from mrtnSelectionBox, with data.height

				function onPopupShow(xPos, yPos) {
					if (!that._isSticked) {
						return;
					}

					that._xPosExtra = xPos;
					that._yPosExtra = yPos;
					that._moveStickyToCollisionPosition.call(this);
				}
			}
		},
		{
			message: 'mrtn-selection-box:close',
			actionFn: () => {
				if (!this._stickyReady) return;
				this._resetStickyPosition.call(this);
			}
		},
		{
			message: PublishableVideoPlayerEvents.CRITICAL_PATH_READY,
			actionFn: () => this.stickMainViewablePlayer()
		},
		{
			message: PublishableVideoStickyEvents.STICK,
			actionFn: () => this.stick()
		},
		{
			message: PublishableVideoStickyEvents.UNSTICK,
			actionFn: () => this.unstick()
		}
	];

	constructor(options: StickyPluginOptions) {
		this._init();
		this._store = options.store;
	}

	/**
	 * Stick the player
	 */
	public stick() {
		// Do nothing if player is already sticked
		if (!this._player || this._isSticked) return;

		// Stick container and check for collisions with other HTML elements of the page behavior displaying it
		this._player.isSticked = this._isSticked = true;
		this._layout.sticked$.next(true);
		this._moveStickyToCollisionPosition();
		this._updatePlayerBoundingRect();
		this._player.publish(PublishableVideoStickyEvents.STICKED);
	}

	/**
	 * Unstick the player
	 */
	public unstick() {
		// Do nothing if player is already unsticked
		if (!this._player || !this._isSticked) return;

		// Unstick container and reset collisions
		this._player.isSticked = this._isSticked = false;
		this._layout.sticked$.next(false);
		this._resetStickyPosition();

		this._player.publish(PublishableVideoStickyEvents.UNSTICKED);
	}

	/**
	 * Event that stick / unstick player on scroll
	 */
	public stickMainViewablePlayer() {
		const that = this;

		if (!this._layout) return;

		const stickUnstickHandler = (shouldDisable: boolean) => {
			// Do nothing if sticky is disabled
			if (!this.enabled && !that._disabledByElementOnScreen) {
				return;
			}

			if (shouldDisable) {
				this._disable(false, false, 'handler');
				this._disabledByElementOnScreen = true;
				return;
			}

			let action;

			if (this._stickBothSide) {
				const topBelowMiddle = isViewportPositionned(
					VP_TOP.IS_BELOW_CONTAINER_MIDDLE
				);
				const bottomAboveMiddle = isViewportPositionned(
					VP_BOTTOM.IS_ABOVE_CONTAINER_MIDDLE
				);
				action = topBelowMiddle || bottomAboveMiddle ? 'stick' : 'unstick';
			} else if (!this._scrollUp) {
				// Scroll down (default behavior): stick when viewport is below the original container
				action = isViewportPositionned(VP_TOP.IS_BELOW_CONTAINER_MIDDLE)
					? 'stick'
					: 'unstick';
			} else {
				// Scroll up (specific case): stick when viewport is above the original container
				// ⚠️ Original player container must have be seen at least once before firing any "scroll-up" sticky
				if (!this._isSticked) {
					action =
						isViewportPositionned(CONTAINER_POSITION.IS_PARTIALLY_WITHIN_VP) &&
						isViewportPositionned(VP_BOTTOM.IS_ABOVE_CONTAINER_MIDDLE)
							? 'stick'
							: undefined;
				} else {
					action = isViewportPositionned(VP_BOTTOM.IS_BELOW_CONTAINER_MIDDLE)
						? 'unstick'
						: undefined;
				}
			}

			if (!action) return;
			l.debug(`Sticky will ${action}`);
			const actionFn = this[action].bind(this);

			if (this._waitForAdLoaded && !this._adLoaded) {
				if (action === 'stick' && !this._store.isViewable(this._instance)) {
					this._instance.player.play();
					return;
				}
			}

			typeof actionFn === 'function' && actionFn();

			if (action === 'stick' && this._disabledByElementOnScreen) {
				this.enabled = true;
				this._instance.player.play();
				this._disabledByElementOnScreen = !this._disabledByElementOnScreen;
			}

			function isViewportPositionned(condition) {
				let res;
				const containerSize = getElementPosition(that._layout.el);
				const windowSize = getWindowPosition();

				switch (condition) {
					case VP_TOP.IS_BELOW_CONTAINER_TOP:
						res = windowSize.top >= containerSize.top;
						break;
					case VP_TOP.IS_BELOW_CONTAINER_MIDDLE:
						res =
							windowSize.top >= containerSize.top + containerSize.height / 2;
						break;
					case VP_BOTTOM.IS_ABOVE_CONTAINER_BOTTOM:
						res = windowSize.bottom <= containerSize.bottom;
						break;
					case VP_BOTTOM.IS_ABOVE_CONTAINER_MIDDLE:
						res =
							windowSize.bottom <= containerSize.top + containerSize.height / 2;
						break;
					case VP_BOTTOM.IS_BELOW_CONTAINER_MIDDLE:
						res =
							windowSize.bottom >= containerSize.top + containerSize.height / 2;
						break;
					case CONTAINER_POSITION.IS_PARTIALLY_WITHIN_VP:
						res =
							windowSize.bottom >= containerSize.top &&
							windowSize.top <= containerSize.bottom;
						break;
					case CONTAINER_POSITION.IS_WITHIN_VP:
						res =
							windowSize.top <= containerSize.top &&
							windowSize.bottom >= containerSize.bottom;
						break;
					default:
						console.error(
							'This position from viewport (' +
								condition +
								') is not yet implemented'
						);
						break;
				}

				return res;
			}
		};

		const shouldDisable = stickyIsOnElement(
			'#taboola-below-article-thumbnails, #taboola-mobile-below-article-thumbnails',
			this._stickyRect as DOMRect
		);
		stickUnstickHandler(shouldDisable);

		function stickyIsOnElement(selector: string, stickyRect: DOMRect) {
			let targetEl: Element | null = null;
			let targetRect: DOMRect | null = null;

			if (!selector) {
				console.error(
					'[Sticky Player Plugin::_stickyIsOnElement] Missing mandatory `selector` parameter'
				);
				return false;
			}

			targetEl = document.querySelector(selector);
			targetRect = targetEl
				? (targetEl as HTMLElement).getBoundingClientRect()
				: null;

			return stickyIsOnTarget(targetRect, stickyRect);

			/**
			 * Check if the sticky is inside the target
			 * @param {DOMRect} targetRect The target DOMRect
			 * @param {DOMRect} stickyRect The sticky DOMRect
			 * @returns Wether the sticky is entered on target element. False if there is no sticky or no target
			 */
			function stickyIsOnTarget(
				targetRect: DOMRect | null,
				stickyRect: DOMRect | null
			) {
				return !targetRect || !stickyRect
					? false
					: // TODO: Implement resize function and uncomment the following lines to be able to check horizontally
					  // targetRect.left < stickyRect.right &&
					  // targetRect.right > stickyRect.left &&
					  targetRect.top <= stickyRect.bottom &&
							targetRect.bottom >= stickyRect.top;
			}
		}
	}

	/**
	 * Initialize the feature
	 */
	private _init() {
		this.enabled = !isStickySessionDisabled();
		if (!this.enabled) return;

		this._initListeners(this._listenersActions);

		this.screenOrientationChanged$.subscribe(this._updatePlayerBoundingRect);
	}

	private _updatePlayerBoundingRect() {
		l.debug('Updating Layout Bounding Rect')();
		this._stickyRect = this._layout.playerWrapper.getBoundingClientRect();
	}

	private _initListeners = (listenersActions: ListenerAction[]) =>
		listenersActions.forEach((listenAction) =>
			PubSub.subscribe(listenAction.message, listenAction.actionFn.bind(this))
		);

	private _initLayout = (
		layout: StickyFormats,
		options: StickyFormatOptions
	) => {
		/**
		 * Select and Spawn the layout
		 */
		const getLayout = (
			format: StickyFormats,
			options: StickyFormatOptions
		): StickyLayout => {
			let layout: StickyLayout;
			const closeBtnSwitchFeatureEnabled = typeof this._stickyFormatOptions
				.closeBtnSwitchFeatureEnabled
				? Boolean(this._stickyFormatOptions.closeBtnSwitchFeatureEnabled)
				: true;
			const defaultSettings = {
				playerId: this._instance.player.id,
				playerContainerId: this._instance.player.id + '-container',
				closeBtnSwitchFeatureEnabled
			};
			const defaultStickyClass = 'sticky-player';

			switch (format) {
				case StickyFormats.BANNER:
					{
						const stickedClasses = [defaultStickyClass];
						const formatOptions = options as BannerStickyOptions;

						layout = new BannerStickyLayout({
							...defaultSettings,
							stickedClasses,
							displayVideosInfos: formatOptions.displayDetails,
							position: formatOptions.position
						});
					}
					break;

				default: {
					const stickedClasses = [
						defaultStickyClass,
						`sticky-${this._stickTo}`
					];

					const formatOptions = options as FlyingStickyOptions;

					if (formatOptions && formatOptions.size === 'large') {
						stickedClasses.push('flying-sticky-large');
					}

					layout = new FlyingStickyLayout({
						...defaultSettings,
						stickedClasses
					});
				}
			}

			return layout;
		};

		const handleLayoutEvents = (event: StickyLayoutEvents) => {
			const handlers = {
				[StickyButtonsEvents.CLOSE]: this._disable.bind(
					this,
					false,
					true,
					'user closing'
				),
				[StickyButtonsEvents.GO_TO_PLAYER]: this._scrollToPlayer,
				notYetImplemented: () =>
					console.error(`This event is not yet implemented: ${event}`)
			};

			const actionFn = handlers[event] || handlers.notYetImplemented;
			actionFn.apply(this);
		};

		this._layout = getLayout(layout, options);
		this._layout.events$.subscribe(handleLayoutEvents);

		const shouldUpdateDetails =
			layout === StickyFormats.BANNER &&
			(options as BannerStickyOptions).displayDetails;

		if (!shouldUpdateDetails) return;

		// Update video infos if needed
		const subject$ = (this._layout as BannerStickyLayout)
			.stickyBannerVideosInfos$;

		this._instance.player.videoInfos$
			.pipe(distinctUntilChanged())
			.subscribe((videoInfos) => {
				const duration = videoInfos.durationSeconds
					? formatSecondsToMinutes(videoInfos.durationSeconds)
					: undefined;

				subject$.next({
					title: videoInfos.title,
					duration
				});
			});
	};

	private _onPlayerReady(id: string, contentStarted?: boolean) {
		if (
			!this.enabled ||
			id !== this._id ||
			(!contentStarted && this._waitForAdLoaded && !this._adLoaded)
		) {
			if (this._waitForAdLoaded && !this._adLoaded) {
				if (this._scrollUp) return; // Do not force play if video cannot stick right now

				// Force play as the player could not be visible and we are waiting for ad loaded to activate sticky
				this._instance.player.play();
			}
			return;
		}

		this._player = this._instance.player;

		const playerContainer = this._player.getContainer();
		if (!playerContainer) return;

		this._container = playerContainer;
		this._stickyReady = true;
	}

	/**
	 * Register the first player instance and generate
	 * @param {StoreInstance} storeInstance
	 */
	private _registerInstance(storeInstance: StoreInstance) {
		const getDefaultFormatOptions = (
			format: StickyFormats
		): StickyFormatOptions => {
			let res: any;

			switch (format) {
				case StickyFormats.FLYING:
					{
						(res as FlyingStickyOptions) = {
							size: 'normal'
						};
					}
					break;

				case StickyFormats.BANNER:
					{
						(res as BannerStickyOptions) = {
							displayDetails: false,
							position: 'bottom'
						};
					}
					break;

				default:
					{
						throw new Error(`Sticky format '${format}' is not yet implemented`);
					}
					break;
			}

			res.closeBtnSwitchFeatureEnabled = true;
			return res;
		};

		const player = storeInstance.player;
		const stickyOptions = storeInstance.sticky;

		this._instance = storeInstance;
		this._id = player.id;
		this._stickTo = stickyOptions.stickTo;
		this._waitForAdLoaded = stickyOptions.waitForAdLoaded;
		this._scrollUp = stickyOptions.scrollUp;
		this._stickBothSide = stickyOptions.stickBothSide;
		this._stickyFormat = stickyOptions.format || StickyFormats.FLYING;
		this._stickyFormatOptions =
			stickyOptions.formatOptions ||
			getDefaultFormatOptions(this._stickyFormat);
	}
	66;
	private _scrollToPlayer() {
		const topPosition =
			this._layout.el.getBoundingClientRect().top +
			document.documentElement.scrollTop -
			DEFAULT_SETTINGS.SCROLL_OFFSET;

		window.scrollTo({
			top: topPosition,
			behavior: 'smooth'
		});

		this._updatePlayerBoundingRect();
	}

	/**
	 * Cancel the disabling timeout
	 */
	private _cancelDisablingTimeout() {
		clearTimeout(this._onCompleteTimeout as any);
		this._onCompleteTimeout = null;
	}

	/**
	 * Disable the sticky feature
	 * @param {boolean} persistant
	 */
	private _disable(
		disabledForSession?: boolean,
		disabledForPage?: boolean,
		reason?: string
	) {
		l.info(`Sticky disabled. Reason: ${reason}`);

		this.enabled = false;
		this.unstick();

		if (disabledForPage || disabledForSession) {
			if (disabledForPage) {
				this._disabledForPage = true;
			}

			if (disabledForSession) {
				setDisabledStickyForSessionState();
			}

			this._player.publish(PublishableVideoStickyEvents.CLOSE);
		}
	}

	/**
	 * Move the player to avoid collisions
	 */
	private _moveStickyToCollisionPosition() {
		this._movePlayer(
			'-' + this._xPosExtra + 'px',
			'-' + this._yPosExtra + 'px'
		);
	}

	/**
	 * Reset sticky to initial position
	 */
	private _resetStickyPosition() {
		this._movePlayer(0, 0);
	}

	/**
	 * Move the player to the give x y position
	 * @param {number} xPos Horizontal position
	 * @param {number} yPos Vertical position
	 */
	private _movePlayer(xPos: number | string, yPos: number | string) {
		// Do nothing if new position is the same
		if (xPos === this._xPos && yPos === this._yPos) return;

		// Save new position
		this._xPos = xPos;
		this._yPos = yPos;

		applyCssTranslate(
			this._container.parentNode as HTMLElement,
			+this._xPos,
			+this._yPos
		);
	}
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.Sticky = (options: StickyPluginOptions) =>
	new StickyPlugin(options);

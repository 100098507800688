import Bowser from "bowser";

import {
	JwPlayerPlaylist,
	PlaylistId
} from './interfaces/player-playlist.interface';
import { VideoPlayerSettings } from './interfaces/VideoPlayerSettings.interface';
import { Manager } from './manager';
import { Store } from './store';
import { VideoConsoleLogger } from './utils/video-logger';
import { VideoLogger, VideoLoggerOptions } from './trackers/aufVideoLogger';
import { Players } from './enums/players.enum';
import { GaVideoTracker } from './trackers/GAVideoTracking';

const l = new VideoConsoleLogger();
window.videoPlayerVersionNumber = '2.3.2';
window.bowser = Bowser.parse(window.navigator.userAgent);

declare global {
	interface Window {
		videoPhpVars: VideoPlayerSettings[];
		videoPlayerVersionNumber: string;
		bowser: Bowser.Parser.ParsedResult;
	}
}

function buildVideoLogger(settings: VideoPlayerSettings) {
	const videoLoggerSettings: VideoLoggerOptions = {
		context: {
			adPlayer:
				settings.adTechno && settings.adTechno.name
					? {
							name: settings.adTechno.name
					  }
					: undefined,
			player: {
				version: window.videoPlayerVersionNumber,
				name: settings.techno ? settings.techno.name : 'undefined',
				videoId: settings.videoId,
				context: settings.context,
				position: settings.position
			}
		}
	};

	return new VideoLogger(videoLoggerSettings);
}

async function buildVideoPlayer(
	playerConstructor: any,
	settings: VideoPlayerSettings
) {
	const isJwPlayer = settings.techno.id === Players.JWPLAYER;
	const isDailymotion = settings.techno.id === Players.DAILYMOTION;
	const isDailymotionEmbed = settings.techno.id === Players.DAILYMOTION_EMBED;

	let playlist = isJwPlayer
		? ({
				sources: settings.sources,
				image: '' + settings.poster
		  } as JwPlayerPlaylist)
		: isDailymotion || isDailymotionEmbed
		? (settings.dailymotionPlaylistID as PlaylistId)
		: undefined;

	const playerSettings = {
		name: settings.techno?.name,
		aspectRatio: settings.aspectRatio,
		fluid: Boolean(settings.aspectRatio),
		height: +settings.height,
		id: settings.playerId,
		muted: settings.autoplay.startOnSight && settings.muted,
		playlist: playlist ? playlist : undefined,
		title: settings.title,
		type: settings.type,
		datePublication: settings.datePublication,
		videoId:
			isDailymotion || isDailymotionEmbed
				? settings.dailymotionId
				: settings.videoId,
		isAdDisabled: settings.isAdDisabled,
		isLazyLoadedPlayer: settings.isLazyLoadedPlayer,
		pmad: settings.pmad,
		pmxd: settings.pmxd
	};

	const player = playerConstructor
		? new playerConstructor(playerSettings)
		: undefined;

	return player;
}

/**
 * Configure ad video player
 */
function buildAdPlayer(
	adPlayerConstructor: any,
	settings: VideoPlayerSettings,
	adTargeting: any,
	player: any
) {
	const adsSettings: any = {
		id: settings.playerId,
		name: settings.adTechno?.name,
		player: player,
		adUnit: settings.adUnit,
		adTargeting,
		allowMultipleTinyAds: settings.allowMultipleTinyAds,
		siteId: settings.siteId,
		isCM: settings.isCM,
		needPegasus: settings.needPegasus,
		hbVideoActive: settings.hbVideoActive,
		position: '' + settings.position,
		adScheduleId: settings.adScheduleId,
		adLabel: settings.adLabel,
		language: settings.adPlayerLanguage,
		midrollActive: settings.midrollActive,
		minTimeMidroll: settings.minTimeMidroll,
		jwpAdvertisingParameters: settings.jwpAdvertisingParameters,
		adRule: settings.adRule
	};

	return adPlayerConstructor ? new adPlayerConstructor(adsSettings) : undefined;
}

function addStoreInstance(
	logger: VideoLogger,
	player: any,
	adPlayer: any,
	settings: VideoPlayerSettings
) {
	const store: Store = Store.get();
	const instanceProps = {
		logger: logger,
		player: player,
		adPlayer: adPlayer,
		context: settings.context,
		position: settings.position,
		autoplay: settings.autoplay,
		sticky: settings.sticky
	};

	store.addInstance(settings.playerId, instanceProps);
}

export async function initAll(
	playerConstructor: any,
	adConstructor: any,
	settings: VideoPlayerSettings
) {
	/* * * * * * * * * * * * * * * * * *
	 *          Init trackers          *
	 * * * * * * * * * * * * * * * * * */
	GaVideoTracker.get(); // Note: gaTracker is not used afterwhile
	if (settings.isComScoreTrackingEnabled) {
		// comScore.init();
	}

	/* * * * * * * * * * * * * * * * * *
	 *    Instantiate Video Objects    *
	 * * * * * * * * * * * * * * * * * */
	const logger = buildVideoLogger(settings);
	const player = await buildVideoPlayer(playerConstructor, settings);

	const adPlayer = !settings.isAdDisabled
		? buildAdPlayer(
				adConstructor,
				settings,
				_getAdTargetingString(settings),
				player
		  )
		: undefined;

	Manager.get({
		labels: {
			nextVideo: settings.nextVideoLabel
		}
	});

	if (!logger || !player || (!settings.isAdDisabled && !adPlayer)) {
		if (!logger) {
			l.error('VideoLogger is undefined !')();
		}

		if (!player) {
			l.error('Player is undefined !')();
		}

		if (!settings.isAdDisabled && !adPlayer) {
			l.error('AdPlayer is undefined !')();
		}

		throw new Error('Missing mandatory object to add store instance');
	}
	addStoreInstance(logger, player, adPlayer, settings);

	function _getAdTargetingString(settings: VideoPlayerSettings) {
		const newAdTargeting =
			';' +
			[
				settings.adTarget,
				'player=' + settings.techno.id,
				'adplayer=' + settings.adTechno.id,
				'context=' + settings.context,
				'video=' + settings.videoId + 'v'
			].join(';');

		return newAdTargeting;
	}
}

export enum PublishableVideoPlayerEvents {
	// Global
	END = 'video.player.end',
	PAUSE = 'video.player.pause',
	PLAY = 'video.player.play',
	READY = 'video.player.ready',
	SEEKING = 'video.player.seek',
	SEEKED = 'video.player.seeked',
	START = 'video.player.start',
	UNMUTE = 'video.player.unmute',
	INITIALIZATION_ERROR = 'video.player.initialization.error',
	BEFORE_COMPLETE = 'video.player.beforeComplete',
	BEFORE_PLAY = 'video.player.beforePlay',
	CONTROLS_CHANGE = 'video.player.controlschange',
	FULLSCREEN_CHANGE = 'video.player.fullscreenchange',
	PLAYBACK_READY = 'video.player.playback_ready',
	VIDEO_CHANGE = 'video.player.videochange',
	VOLUME_CHANGE = 'video.player.volumechange',
	CRITICAL_PATH_READY = 'video.player.dailymotion-embed.criticalready',
	DAILYMOTION_EMBED_LAZY_READY = 'video.player.dailymotion-embed.lazy-ready'
}

export enum PublishableVideoContentEvents {
	TIME_UPDATE = 'video.player.inProgress',
	VIDEO_END = 'video.player.complete',
	DURATION_CHANGE = 'video.player.content.duration_change',
	LOADED_METADATA = 'video.player.content.loaded_metadata',
	SUBTITLE_CHANGE = 'video.player.content.subtitle_change',
	SUBTITLES_AVAILABLE = 'video.player.content.subtitles_available',
	PLAYBACK_RESOLUTION = 'video.player.content.playback_resolution',
	PLAYING = 'video.player.content.playing',
	QUALITIES_AVAILABLE = 'video.player.content.qualities_available',
	QUALITIES_CHANGED = 'video.player.content.qualities_changed',
	PROGRESS = 'video.player.content.progress',
	VIDEO_START = 'video.player.content.start',
	WAITING = 'video.player.content.waiting'
}

export enum PublishableVideoUserEvents {
	TOGGLE_PLAY_PAUSE = 'video.player.user.togglePlayPause'
}

export enum PublishableVideoLogEvents {
	ERROR = 'video.player.log.error'
}

export enum PublishableVideoAdEvents {
	AD_CLICK = 'video.ad.click',
	AD_COMPANIONS = 'video.ad.companions',
	AD_END = 'video.ad.complete',
	AD_PAUSE = 'video.ad.pause',
	AD_PLAY = 'video.ad.play',
	AD_READY = 'video.ad.ready',
	AD_START = 'video.ad.start',
	AD_TAG_READY = 'video.ad.tag.ready',
	AD_TIMEUPDATE = 'video.ad.time_update',
	AD_IMPRESSION = 'video.ad.impression',
	AD_REQUEST = 'video.ad.request',
	AD_BLOCKED = 'video.ad.block',
	AD_RESUME = 'video.ad.resume',
	AD_LOADED = 'video.ad.loaded',
	AD_CONTENT_RESUME = 'video.ad.contentresume',
	AD_BID_FOUND = 'video.ad.bidfound',
	AD_CANCEL = 'video.ad.cancel',
	AD_UNFILLED = 'video.ad.unfilled',
	AD_DURATIONCHANGE = 'video.ad.duration_change',
	HEAVY_ADS = 'video.ad.heavy_ad_error'
}

export enum PublishableVideoStoreEvents {
	ADD_INSTANCE = 'video.store.addInstance'
}

export enum PublishableVideoStickyEvents {
	STICKY_READY = 'video.sticky.ready',
	STICKY_CONTENT_START = 'video.sticky.content.start',
	STICK = 'video.player.sticky.stick',
	UNSTICK = 'video.player.sticky.unstick',
	STICKED = 'video.player.sticky.sticked',
	UNSTICKED = 'video.player.sticky.unsticked',
	SCROLL_TO_PLAYER = 'video.player.sticky.gotoplayer',
	CLOSE = 'video.player.sticky.close'
}

(window as any).PublishableVideoPlayerEvents = PublishableVideoPlayerEvents;
(window as any).PublishableVideoContentEvents = PublishableVideoContentEvents;
(window as any).PublishableVideoUserEvents = PublishableVideoUserEvents;
(window as any).PublishableVideoLogEvents = PublishableVideoLogEvents;
(window as any).PublishableVideoAdEvents = PublishableVideoAdEvents;
(window as any).PublishableVideoStoreEvents = PublishableVideoStoreEvents;
(window as any).PublishableVideoStickyEvents = PublishableVideoStickyEvents;

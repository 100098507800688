import { el } from 'redom';

export const getChildren = (icon?: string, text?: string): HTMLElement[] => {
	const children: HTMLElement[] = [];
	if (icon) {
		const iconEl = el('i');
		iconEl.className = `icon ${icon}`;
		children.push(iconEl);
	}

	if (text) {
		const textEl = el('span');
		textEl.className = 'button-label';
		children.push(textEl);
	}

	return children;
};

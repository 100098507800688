import { VideoConsoleLogger } from 'src/utils/video-logger';
import { Players } from '../../enums/players.enum';
import {
	PublishableVideoAdEvents,
	PublishableVideoContentEvents,
	PublishableVideoPlayerEvents,
	PublishableVideoStickyEvents,
	PublishableVideoUserEvents
} from '../../enums/publishable-events.enum';
import { ListenerAction } from '../../interfaces/listener-action';
import { Store, StoreInstance } from '../../store';
import debounce from 'lodash.debounce';
import PubSub from 'pubsub-js';
import { StickyPlugin } from '../sticky/sticky-core';

const logger = new VideoConsoleLogger();

export interface AutoplayPluginOptions {
	store: Store;
}

export class AutoplayPlugin {
	public name = 'autoplay';
	private _store: Store;
	private _stickyEnabled: boolean;
	private _enabled = false;
	private _storeInstances: Map<string, StoreInstance> = new Map<
		string,
		StoreInstance
	>();
	public scrollEvent: (...args: any[]) => void =
		this._autoplayMainViewablePlayer.bind(this);

	public async _autoplayMainViewablePlayer(
		forceEnable?: boolean
	): Promise<void> {
		if (!this._enabled && !forceEnable) {
			return;
		}

		const currentInstance = this._store.getCurrentPlayableInstance();

		// Stop trying to autoplay if an instance is already playing and still viewable
		const videoContainerViewable = currentInstance
			? this._store.isViewable(currentInstance)
			: false;
		const playing = currentInstance
			? await this._store.isPlaying(currentInstance)
			: false;
		const instanceIsPlayingAndViewable = playing && videoContainerViewable;

		if (instanceIsPlayingAndViewable) return;

		const canAutoplay = (
			playing: boolean,
			videoContainerViewable: boolean,
			videoStarted: boolean,
			adPaused: boolean
		) =>
			!playing && !adPaused
				? !videoStarted
					? videoContainerViewable
					: false
				: false;

		const canPause = (
			playing: boolean,
			videoContainerViewable: boolean,
			isStickyEnabled: boolean,
			isSticked: boolean
		) =>
			playing && !videoContainerViewable && !isStickyEnabled && !isSticked && !this._stickyClosing;

		const playableInstances = Array.from(this._storeInstances.values()).filter(
			(instance) =>
				instance.autoplay &&
				instance.autoplay.enabled &&
				instance.autoplay.startOnSight
		);

		// Every permits to break if return a falsy, we must return truthy to continue iterating
		for (const instance of playableInstances) {
			const adPaused: boolean =
				instance.adPlayer &&
				instance.adPlayer.adsState &&
				instance.adPlayer.adsState.paused;
			const playing = await this._store.isPlaying(instance);
			const viewable = this._store.isViewable(instance);
			const videoStarted = instance.player.videoStarted;
			const isSticky = instance.player.isSticked;
			const isStickyEnabled = this._stickyEnabled;
			const playVideo = canAutoplay(playing, viewable, videoStarted, adPaused);
			const pauseVideo = canPause(playing, viewable, isStickyEnabled, isSticky);

			if (playVideo) {
				logger.debug('Should PLAY video')();
				logger.info(
					`[${instance.player.id}] canAutoplay(${playing}, ${viewable}, ${videoStarted}, ${adPaused})`
				)();
				this._store.getActivePlayer(instance).play(); // TODO: Use an async handler and delete sleep
				this._sleep();
			} else if (pauseVideo) {
				logger.debug('Should PAUSE video')();
				[instance.adPlayer, instance.player]
					.filter((p) => !!p)
					.forEach((player) => {
						player.pause();
					});

				// Permit to restick after a sticky disable (Taboola ...)
				// TODO: Refactor Taboola GTM tag and behavior
				instance.sticky.enabled = true;
			}

			if (playVideo || pauseVideo) {
				this._store.setCurrentPlayableInstanceId(instance.player.id);
				break;
			}
		}
	}

	private _stickyClosing = false;

	private _listenersActions: ListenerAction[] = [
		{
			message: PublishableVideoPlayerEvents.READY,
			actionFn: (msg, data) => {
				this._registerInstance.call(this, data.id);
				this._debouncedAutoplay.call(this);
			}
		},
		{
			message: PublishableVideoContentEvents.VIDEO_END,
			actionFn: (msg, data) => this._unregisterInstance(data.id)
		},
		{
			message: PublishableVideoAdEvents.AD_READY,
			actionFn: () => this._debouncedAutoplay.call(this)
		},
		{
			message: PublishableVideoUserEvents.TOGGLE_PLAY_PAUSE,
			actionFn: (msg, data) => this._toggle.bind(this, data.id)
		},
		{
			message: PublishableVideoStickyEvents.CLOSE,
			actionFn: () => {
				this._stickyEnabled = false;
				const instance =
					this._store.getCurrentPlayableInstance() as StoreInstance;
				this._stickyCloseHandler.call(this, instance);
			}
		},
		{
			message: PublishableVideoAdEvents.AD_END,
			actionFn: () => {
				const instance =
					this._store.getCurrentPlayableInstance() as StoreInstance;
				const isSticky = instance.player.isSticked;

				if (!isSticky && this._stickyClosing) {
					this._stickyCloseHandler.call(this, instance);
				}
			}
		},
		{
			message: PublishableVideoPlayerEvents.DAILYMOTION_EMBED_LAZY_READY,
			actionFn: () => this._autoplayMainViewablePlayer(true)
		},
		{
			message: PublishableVideoStickyEvents.STICKED,
			actionFn: () => {
				this._stickyEnabled = true;
				this._debouncedAutoplay.call(this);
			}
		}
	];

	constructor(options: AutoplayPluginOptions) {
		this._store = options.store;
		this._initListeners(this._listenersActions);
	}

	private _initListeners = (listenersActions: ListenerAction[]) =>
		listenersActions.forEach((listenAction) =>
			PubSub.subscribe(listenAction.message, listenAction.actionFn.bind(this))
		);

	private _stickyCloseHandler(instance: StoreInstance) {
		if (!instance) {
			throw new Error('Missing mandatory `instance` parameter');
		}
		if (!instance.adPlayer) {
			throw new Error('Missing mandatory `instance.adPlayer` parameter');
		}

		const adsState = instance.adPlayer.adsState || {};

		this._stickyClosing = isPlayingAds(instance, this._stickyClosing, adsState);

		if (this._stickyClosing) return;

		this._store.getActivePlayer(instance).pause();
		this._stickyClosing = !this._stickyClosing;

		function isPlayingAds(
			instance: StoreInstance,
			stickyClosing: boolean,
			adsState: any
		) {
			logger.debug(`getIsStickyClosing()`)();

			const isPlayingAds =
				!stickyClosing &&
				typeof adsState.playing === 'boolean' &&
				adsState.playing &&
				!adsState.ended;

			logger.debug('isPlayingAds: ' + isPlayingAds)();
			return isPlayingAds;
		}
	}

	private _registerInstance(id: string) {
		logger.debug(`_registerInstance(${id})`)();
		const instance = this._store.getInstance(id);

		if (!instance || !instance.autoplay || !instance.autoplay.startOnSight)
			return;

		instance.autoplay.enabled = true;
		this._storeInstances.set(id, instance);
		this._enabled = true;
	}

	private _unregisterInstance(id: string) {
		if (!this._storeInstances.has(id)) return;

		this._storeInstances.delete(id);
	}

	private _debouncedAutoplay = () =>
		debounce(this._autoplayMainViewablePlayer, 200, {
			leading: true,
			maxWait: 200,
			trailing: true
		}).call(this);

	private _toggle(id: string): void {
		const instance = this._store.getInstance(id);
		if (!instance || !instance.autoplay) return;

		instance.autoplay.enabled = !instance.autoplay.enabled;
	}

	private _sleep(timeout: number = 1000): void {
		this._enabled = false;
		setTimeout(() => (this._enabled = true), timeout);
	}
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.Autoplay = (options: AutoplayPluginOptions) =>
	new AutoplayPlugin(options);

import {
	DailymotionEmbedInternalEvents,
	DailymotionInternalEvents
} from '../enums/dailymotion-internal-events.enum';
import { Players } from '../enums/players.enum';
import { AdsParams } from 'src/interfaces/ads-params.interface';
import {
	DailymotionEmbedApi,
	DailymotionEmbedSetupOptions,
	DailymotionState
} from '../interfaces/dailymotion-embed.interface';
import { DailymotionEmbedPlayerOptions } from '../interfaces/player-options.interface';
import { PlaylistId } from '../interfaces/player-playlist.interface';
import { VideoInfos } from '../interfaces/video-infos.interface';
import { PermutiveService } from '../utils/permutive.service';
import { VideoConsoleLogger } from '../utils/video-logger';
import Player from './_adapter';
import {
	PublishableVideoAdEvents,
	PublishableVideoContentEvents,
	PublishableVideoPlayerEvents,
	PublishableVideoStickyEvents
} from '../enums/publishable-events.enum';
import PubSub from 'pubsub-js';

const logger = new VideoConsoleLogger();

declare global {
	interface Window {
		dailymotion: any;
		AufVideo: any;
		permutive: any;
	}
}

export class DailymotionEmbedPlayer extends Player {
	public name = Players.DAILYMOTION_EMBED;
	public height: number;
	public contentStarted = false;
	public contentEnded = false;
	public setup: DailymotionEmbedSetupOptions;
	public api: DailymotionEmbedApi;
	public isAdDisabled = false;
	public adsRules: { pmad: number; pmxd: number } | undefined;
	public playlist: PlaylistId;

	private dailymotion = window.dailymotion;
	private _currentVolume: number;
	private _isLazyLoaded: boolean;
	private _playerBasicOptions: any;
	private _lazyContentLoaded = false;
	private _refreshAdsConfig = true;

	constructor(options: DailymotionEmbedPlayerOptions) {
		super({ ...options, name: 'dailymotion-embed' });

		logger.debug('Constructing DailymotionEmbedPlayer', options)();

		if (!this.dailymotion || !options) {
			this.logError('Unable to init player (missing API or options)');
			return;
		}

		this.height = options.height;

		if (options.playlist) {
			this.playlist = options.playlist;
		}

		this.adsRules =
			options.pmad && options.pmxd
				? {
						pmad: options.pmad,
						pmxd: options.pmxd
				  }
				: undefined;

		this._isLazyLoaded = options.isLazyLoadedPlayer;

		this._playerBasicOptions = {
			params: {
				autoplay: false,
				mute: this.muted,
				'disable-queue': this.playlist ? true : false,
				'ui-logo': false,
				customConfig: {}
			}
		};

		this.isAdDisabled = Boolean(options.isAdDisabled);

		if (this.isAdDisabled) {
			this.disableAds();
		}
	}

	protected disableAds() {
		logger.info('Ads are now disabled')();
		this._playerBasicOptions.params.customConfig = {
			customParams: 'noAds'
		};
	}

	public createLazyPlayer = async () => {
		this.api = await this.dailymotion.createPlayer(
			this.id,
			this._playerBasicOptions
		);

		return Promise.resolve();
	};

	protected async initApi(adsParams: AdsParams): Promise<void> {
		logger.debug('Initializing Dailymotion API')();

		const options = {
			...this._playerBasicOptions,
			video: this.videoId,
			playlist: this.playlist
		};

		if (adsParams && adsParams.ui && adsParams.custom) {
			const adsDailyParams: string[] = [];
			adsDailyParams.push(adsParams.ui);

			// Limit custom permutive chars number to avoid too long url error
			adsDailyParams.push(
				getCustomParamsWithTruncatedPermutive(adsParams.custom, 250)
			);

			options.params.customConfig = {
				customParams:
					encodeURIComponent(adsDailyParams[0]) +
					'/' +
					encodeURIComponent(adsDailyParams.slice(1).join('&'))
			};

			// Adds AdRules (should not be encoded to no be included into cust_params when it goes to gampad)
			if (this.adsRules) {
				var adRulesStr =
					'pmad=' + this.adsRules.pmad + '&pmnd=0&pmxd=' + this.adsRules.pmxd;
				options.params.customConfig.customParams += `&${adRulesStr}`;
			}
		}

		this.setup = options;
		logger.debug(JSON.stringify(this.setup))();

		this.api =
			this.api || (await this.dailymotion.createPlayer(this.id, this.setup));

		// Attach permutive events
		const attachPermutive = () => {
			logger.info('Attaching Permutive events to Dailymotion')();
			const permutiveService = new PermutiveService(
				window.permutive,
				this.dailymotion
			);
			permutiveService.attachPermutiveEventsToDailyEmbed(this.api);
		};

		console.log('window.permutive', window.permutive);

		window.permutive
			? window.permutive.ready(attachPermutive, 'realtime')
			: window.addEventListener('PermutiveLoaded', attachPermutive, false);

		if (!this.api) {
			PubSub.publish('player_initialization_error', {
				url: window.location.href
			});

			const errMsg = 'Dailymotion API not initialized';
			logger.error(errMsg);
			return Promise.reject(errMsg);
		}

		function getCustomParamsWithTruncatedPermutive(
			customParams: string,
			maxValues: number
		) {
			let res = customParams;
			const matchPermutive = customParams.match(/permutive=([\d,]+)/);
			const permutiveValues = matchPermutive ? matchPermutive[1] : undefined;

			if (permutiveValues) {
				var truncatedPermutiveValues = permutiveValues
					.split(',')
					.splice(0, maxValues)
					.join(',');
				res = customParams.replace(
					'permutive=' + permutiveValues,
					'permutive=' + truncatedPermutiveValues
				);
			}

			return res;
		}

		return Promise.resolve();
	}

	private _lazyLoadContent() {
		if (this._isLazyLoaded && !this._lazyContentLoaded) {
			logger.debug('_lazyLoadContent()')();
			// update values for advertising
			this.dailymotion.getPlayer(this.id).then((player) => {
				player.setCustomConfig({
					customParams: this.setup.params.customConfig?.customParams
				});

				player.updateParams({
					mute: this.muted
				});

				player.loadContent({
					video: this.setup.video,
					playlist: this.playlist
				});

				this._lazyContentLoaded = true;
			});
		}
	}

	protected initListeners() {
		PubSub.subscribe(PublishableVideoPlayerEvents.READY, () => {
			logger.debug('_initListeners(): Player Ready')();

			this._lazyLoadContent();
		});
	}

	protected initApiListeners() {
		this.dailymotion.getPlayer(this.id).then((player) => {
			// Player Events
			player.on(this.dailymotion.events.PLAYER_CRITICALPATHREADY, async () => {
				await this.onReady();
				this.publish(PublishableVideoPlayerEvents.CRITICAL_PATH_READY);
			});
			player.on(this.dailymotion.events.PLAYER_CONTROLSCHANGE, () => {
				logger.debug('controls changed')();

				this.publish(PublishableVideoPlayerEvents.CONTROLS_CHANGE);
			});
			player.on(this.dailymotion.events.PLAYER_START, () =>
				this.publish(PublishableVideoPlayerEvents.START)
			);
			player.on(this.dailymotion.events.PLAYER_END, () =>
				this.publish(PublishableVideoPlayerEvents.END)
			);
			player.on(
				DailymotionInternalEvents.FULLSCREEN_CHANGE /* Nothing to bind */,
				() => this.publish(PublishableVideoPlayerEvents.FULLSCREEN_CHANGE)
			);
			player.on(this.dailymotion.events.VIDEO_SEEKSTART, () =>
				this.publish(PublishableVideoPlayerEvents.SEEKING)
			);
			player.on(this.dailymotion.events.VIDEO_SEEKEND, () =>
				this.publish(PublishableVideoPlayerEvents.SEEKED)
			);
			player.on(
				this.dailymotion.events.PLAYER_VIDEOCHANGE,
				async (state: DailymotionState) => {
					logger.debug('video changed')();

					// Update internal values
					this.videoId = state.videoId;
					this.videoTitle = state.videoTitle;
					this.videoDuration = state.videoDuration;

					this.publish(PublishableVideoPlayerEvents.VIDEO_CHANGE);

					const videoInfos: VideoInfos = {
						id: this.videoId,
						title: this.videoTitle,
						durationSeconds: this.videoDuration
					};

					// Update current video infos
					this.videosPlayed.push(videoInfos);
					this.videoInfos$.next(videoInfos);
				}
			);

			player.on(
				this.dailymotion.events.PLAYER_VOLUMECHANGE,
				(state: DailymotionState) => {
					// Track unmute once by player
					logger.debug(`New Volume State: ${state.playerVolume}`);
					if (
						state.playerVolume > 0 &&
						!state.playerIsMuted &&
						this.muted &&
						!this._userUnmuted
					) {
						this._userUnmuted = true;
						this.muted = false;
						this.publish(PublishableVideoPlayerEvents.UNMUTE);
					}

					this.publish(PublishableVideoPlayerEvents.VOLUME_CHANGE);
				}
			);

			// Video Events
			player.on(
				this.dailymotion.events.VIDEO_START,
				async (data: DailymotionState) => {
					this.contentStarted = true;
					this.contentEnded = false;

					this._refreshAdsForNextVideo(this._refreshAdsConfig);
					await this.onContentStart.call(this);

					this.publish(PublishableVideoContentEvents.VIDEO_START);
				}
			);
			player.on(this.dailymotion.events.AD_START, async () => {
				await this.onContentStart.call(this);
			});
			player.on(
				this.dailymotion.events.VIDEO_END,
				this.onContentEnd.bind(this)
			);
			player.on(this.dailymotion.events.VIDEO_PAUSE, this.onPause.bind(this));
			player.on(this.dailymotion.events.VIDEO_PLAY, this.onPlay.bind(this));
			player.on(this.dailymotion.events.VIDEO_PLAYING, () =>
				this.publish(PublishableVideoContentEvents.PLAYING)
			);
			player.on(this.dailymotion.events.VIDEO_DURATIONCHANGE, () => {
				const message = PublishableVideoContentEvents.DURATION_CHANGE;
				this.publish(message);
			});
			player.on(this.dailymotion.events.VIDEO_SUBTITLESCHANGE, () =>
				this.publish(PublishableVideoContentEvents.SUBTITLE_CHANGE)
			);
			player.on(this.dailymotion.events.VIDEO_SUBTITLESREADY, () =>
				this.publish(PublishableVideoContentEvents.SUBTITLES_AVAILABLE)
			);
			player.on(this.dailymotion.events.VIDEO_QUALITIESREADY, () =>
				this.publish(PublishableVideoContentEvents.QUALITIES_AVAILABLE)
			);
			player.on(this.dailymotion.events.VIDEO_QUALITYCHANGE, () =>
				this.publish(PublishableVideoContentEvents.QUALITIES_CHANGED)
			);
			player.on(this.dailymotion.events.VIDEO_TIMECHANGE, () =>
				this.publish(PublishableVideoContentEvents.TIME_UPDATE)
			);
			player.on(this.dailymotion.events.VIDEO_PROGRESS, () =>
				this.publish(PublishableVideoContentEvents.PROGRESS)
			);
		});
	}

	protected initErrorsListeners() {
		// https://developer.dailymotion.com/api/#error-codes
		this.api.on(
			DailymotionEmbedInternalEvents.PLAYER_ERROR,
			function (err) {
				var error = this.api.error || {
					code: 'DM Unknown Error',
					message: 'Cannot catch DM error'
				};
				var message =
					'[' + error.code + '] ' + error.message.replace(/\+/g, ' ');
				logger.error(message)();
				this.logError(message);
			}.bind(this)
		);
	}

	public async init(...args: any[]): Promise<void> {
		await super.init(...args);
		await this.onReady();

		return Promise.resolve();
	}

	protected prePlay = () => {}; // Do Nothing
	protected postPlay = () => {}; // Do Nothing
	protected postReady = () => {}; // Do Nothing

	private async onContentStart() {
		const state = await this.api.getState();

		// Keep video volume to track unmute later
		const userVolume = state.playerVolume;
		if (userVolume !== this._currentVolume) {
			this._currentVolume = userVolume;
		}

		this.publish(PublishableVideoStickyEvents.STICKY_CONTENT_START);
	}

	private _refreshAdsForNextVideo(shouldRefresh: boolean) {
		if (!shouldRefresh) return;

		const doRefreshAdsConfig = async () => {
			const state = await this.api.getState();

			const timeLeft = state.videoDuration - state.videoTime;
			const shouldRefresh = timeLeft < 10;

			if (shouldRefresh) {
				this.api.setCustomConfig({
					customParams: this.setup.params.customConfig?.customParams
				});

				window.clearInterval(reloadAdsConfigListener);
			}
		};
		const reloadAdsConfigListener = window.setInterval(
			doRefreshAdsConfig,
			2000
		);
	}

	private onContentEnd() {
		this.contentEnded = true;
		this.contentStarted = false;
		this.publish(PublishableVideoContentEvents.VIDEO_END);
	}

	public getRollPosition() {
		return this.isPreroll()
			? 'preroll'
			: this.isPostRoll()
			? 'postRoll'
			: this.isMidroll()
			? 'midroll'
			: undefined;
	}

	// ==== Player API functions
	// Playback functions
	public isPaused = async () => {
		const state = await this.api.getState();
		const isPlaying = state.playerIsPlaying;
		return !isPlaying;
	};
	public isPreroll = () => !this.contentStarted;
	public isMidroll = () => this.contentStarted && !this.contentEnded;
	public isPostRoll = () => this.contentEnded;
	public isMuted = async () => (await this.api.getState()).playerIsMuted;
	public isFullscreen = async () =>
		(await this.api.getState()).playerPresentationMode === 'fullscreen';
	public load = (video: string, playlist: string, startTime?: number) =>
		this.api.loadContent({ video, playlist, startTime });

	// Sound
	public mute = () => this.api.setMute(true);
	public unmute = () => {
		this.muted = false;
		this.publish(PublishableVideoPlayerEvents.UNMUTE);

		return this.api.setMute(false);
	};

	// Player informations/unifygroup/aufeminin/aufeminin-php/-/clusters
	public getContainer = () => document.getElementById(this.id);
	public getPosition = async () => (await this.api.getState()).videoTime;
	public getDuration = async () => (await this.api.getState()).videoDuration;
	public getVideo = async () => (await this.api.getState()).videoId;

	private _getDailymotionIframe = () =>
		document.getElementsByClassName('dailymotion-player')[0];

	public getFile() {
		const rx =
			/((?:http:\/\/)|(?:https:\/\/))(www.)?((?:[a-zA-Z0-9]+\.[a-z]{3})|(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?::\d+)?))([\/a-zA-Z0-9\.]*)/;
		const iframeSrc = this._getDailymotionIframe()?.getAttribute('src');
		const match = iframeSrc?.match(rx);
		return match ? match[0] : undefined;
	}

	public getQuality = async () => (await this.api.getState()).videoQuality;
	public getVolume = async () => (await this.api.getState()).playerVolume;
	// Contains error code, title and message about the last error that occurred in the player
	public getLastError = async () => (await this.api.getState()).playerError;
	public hasControls = async () =>
		(await this.api.getSettings()).enableControls;
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.DailymotionEmbedPlayer = DailymotionEmbedPlayer;

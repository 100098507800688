// Event listener handler
export function afAddEvent(elt, event, listener, capture) {
	if (elt && elt.addEventListener) {
		elt.addEventListener(event, listener, capture);
		return true;
	} else if (elt && elt.attachEvent) {
		return elt.attachEvent('on' + event, listener);
	} else if (elt) {
		var oldListener = elt['on' + event];
		elt['on' + event] = function (e) {
			if (oldListener) {
				oldListener(e);
			}
			return listener(e);
		};
		return oldListener;
	}
}

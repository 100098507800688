import { PublishableVideoPlayerEvents } from '../../enums/publishable-events.enum';
import { Store } from '../../store';
import PubSub from 'pubsub-js';

export interface DebugPluginOptions {
	store: Store;
}

export class DebugPlugin {
	public name = 'debug';
	private _store: Store;

	constructor(options: DebugPluginOptions) {
		this._store = options.store;
		this._initListeners();
	}

	private _initListeners = () =>
		PubSub.subscribe(PublishableVideoPlayerEvents.READY, (msg, data) =>
			this._showDebugElement(data.id)
		);

	private _showDebugElement(playerId: string) {
		const instance = this._store.getInstance(playerId);
		if (!instance) return;

		const player = instance.player;

		const container = document.createElement('div');
		container.style.cursor = 'pointer';
		container.classList.add('debug-module-container', 'hidden');
		container.innerHTML = '🐞';
		container.onclick = () => console.info('debug', instance);

		const playerContainer = document.getElementById(player.id + '-container');
		if (!playerContainer) throw new Error('Player container not found !');
		playerContainer.classList.add('video-player.debug');
		playerContainer.appendChild(container);
	}
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.Debug = DebugPlugin;

// This file's responsability is to centralize all data about all specific videoplayers instances

import { StickySettings } from './interfaces/sticky-settings.interface';
import { DailymotionEmbedPlayer } from './players/dailymotion-embed';
import { JwPlayer } from './players/jwplayer';
import { VJsPlayer } from './players/video-js';
import { isInViewport } from './plugins/sticky/utils/dom.util';
import PubSub from 'pubsub-js';

export interface StoreInstance {
	player: JwPlayer | VJsPlayer | DailymotionEmbedPlayer;
	adPlayer: any;
	logger: any;
	sticky: StickySettings & { enabled?: boolean };
	autoplay: any;
	context: any;
	position: any;
}

export class Store {
	private viewableTreshold = 0.5;
	private static instance: Store;
	private constructor() {}
	instances: Map<string, StoreInstance> = new Map<string, StoreInstance>();
	currentPlayableInstanceId: string;

	public addInstance(id: string, instance: StoreInstance): void {
		if (!instance.player) {
			instance.logger.logError(
				'Invalid player instance: a mandatory information is missing'
			);
			return;
		}

		console.log('id', id);
		const thisInstance = this.instances.get(id);
		if (thisInstance) {
			instance.logger.logError(
				'A player has already been initialized with this id: ' + id
			);

			console.error('found instance', thisInstance);

			return;
		}

		// Block autoplay by default until player is ready
		instance.autoplay.enabled = instance.autoplay.enabled || false;

		// Add everything to the store
		this.instances.set(id, instance);

		PubSub.publish('video.store.addInstance', {
			id: id,
			instance: this.instances.get(id)
		});
	}

	public getInstance(id: string) {
		return this.instances.get(id);
	}

	public getCurrentPlayableInstance(): StoreInstance | undefined {
		return this.getInstance(this.currentPlayableInstanceId);
	}

	public setCurrentPlayableInstanceId(id: string): void {
		this.currentPlayableInstanceId = id;
	}

	public isPlaying = async (instance: StoreInstance): Promise<boolean> =>
		instance &&
		((instance.adPlayer && instance.adPlayer.isPlaying()) ||
			await instance.player?.isPlaying());

	public getActivePlayer = (instance) =>
		instance.adPlayer && instance.adPlayer.isActive()
			? instance.adPlayer
			: instance.player;

	public isViewable = (instance: StoreInstance): boolean =>
		instance &&
		isInViewport(
			instance.player?.getContainer() as HTMLElement,
			this.viewableTreshold
		);

	public static get(): Store {
		if (!Store.instance) {
			Store.instance = new Store();
		}

		return Store.instance;
	}
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.Store = window.AufVideo.Store || Store;

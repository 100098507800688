import { el, setChildren } from 'redom';
import { fromEvent, Observable } from 'rxjs';

import { StickyButtonOptions } from './button.interface';
import { getChildren } from './buttons.util';
import { getClassname } from '../../../utils/get-classname.util';
import { StickyBaseElement } from '../../base-element';
import { ReactiveClickableElement } from '../../../interfaces/reactive-clickable-element.interface';

const DEFAULT_CLASSLIST = ['video-player-sticky-custom-btn'];

export class StickyButton
	extends StickyBaseElement
	implements ReactiveClickableElement
{
	el: HTMLButtonElement;
	clicked$: Observable<Event>;

	constructor(settings: StickyButtonOptions) {
		const { icon, text, customClass } = settings;

		super(el('button'), getClassname(DEFAULT_CLASSLIST, customClass));
		setChildren(this.el, getChildren(icon, text));
		this.clicked$ = fromEvent(this.el, 'click');
	}
}

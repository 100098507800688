import DailymotionEmbedAdPlayer from 'src/adPlayers/dailymotion-embed';
import { start } from 'src/main';
import { DailymotionEmbedPlayer } from 'src/players/dailymotion-embed';

// Wait for Dailymotion to be defined before starting player
const interval = setInterval(function () {
	// get elem
	if (typeof window.dailymotion === 'undefined') return;
	clearInterval(interval);

	// the rest of the code
	start(DailymotionEmbedPlayer, DailymotionEmbedAdPlayer);
}, 100);

import { el } from 'redom';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { VideoConsoleLogger } from 'src/utils/video-logger';
import { Visibility } from '../../enums/visibility.enum';
import { StickyLayoutEvents } from '../../types/sticky-events.type';

import { getClassname } from '../../utils/get-classname.util';
import { mergeFunctions } from '../../utils/merge-functions.util';
import { StickyBaseElement } from '../base-element';
import { StickyLayoutOptions } from './base-layout.interface';

const DEFAULT_CLASSLIST = ['video-player-sticky-container'];
const logger = new VideoConsoleLogger();

export class StickyBaseLayout extends StickyBaseElement {
	el: HTMLDivElement;
	playerWrapper: HTMLElement;
	parentNode: ParentNode;
	playerElement: HTMLElement;
	toggleableElements: StickyBaseElement[] = [];

	sticked$ = new Subject<boolean>();
	stickedClasses: string[];

	closeBtnVisibility$: BehaviorSubject<Visibility>;

	events$: Observable<StickyLayoutEvents>;

	constructor(settings: StickyLayoutOptions) {
		const { customClass, playerContainerId, playerId, stickedClasses } =
			settings;
		super(el('div'), getClassname(DEFAULT_CLASSLIST, customClass));

		this.stickedClasses = stickedClasses;

		try {
			this.playerWrapper = this._getPlayerWrapper(playerContainerId);
			this.parentNode = this._getParentNode(this.playerWrapper);
			this.playerElement = this._getPlayer(playerId);
		} catch (error) {
			logger.error(error)();
			throw new Error(error);
		}

		this.sticked$
			.pipe(distinctUntilChanged())
			.subscribe(mergeFunctions(this.toggleElements, this.toggleStickedClass));

		const defaultCloseBtnVisibility = settings.closeBtnSwitchFeatureEnabled ? Visibility.HIDDEN : Visibility.VISIBLE;
		this.closeBtnVisibility$ = new BehaviorSubject(defaultCloseBtnVisibility);
	}

	protected toggleElements = (sticked: boolean) =>
		this.toggleableElements.forEach((el) => (sticked ? el.show() : el.hide()));
	protected toggleStickedClass = (sticked: boolean) =>
		this.playerWrapper.classList[sticked ? 'add' : 'remove'].apply(
			this.playerWrapper.classList,
			this.stickedClasses
		);

	private _getPlayer = (playerId: string) => {
		const playerContainer = document.getElementById(playerId);

		if (!playerContainer) {
			throw new Error(`Cannot find player with id "${playerId}"`);
		}

		return playerContainer;
	};

	private _getPlayerWrapper = (playerContainerId: string) => {
		const playerContainer = document.getElementById(playerContainerId);

		if (!playerContainer) {
			throw new Error(
				`Cannot find player container with id "${playerContainerId}"`
			);
		}

		return playerContainer;
	};

	private _getParentNode = (playerContainer: HTMLElement) => {
		const parentNode = playerContainer?.parentNode;

		if (!parentNode) {
			throw new Error(
				`Cannot find parent node of player with id "${playerContainer.id}"`
			);
		}

		return parentNode;
	};
}

import { Subject } from 'rxjs';
import { VideoInfos } from 'src/interfaces/video-infos.interface.js';
import { VideoConsoleLogger } from 'src/utils/video-logger.js';
import { AspectRatio } from '../enums/aspect-ratio.enum.js';
import {
	PublishableVideoAdEvents,
	PublishableVideoContentEvents,
	PublishableVideoLogEvents,
	PublishableVideoPlayerEvents,
	PublishableVideoStickyEvents,
	PublishableVideoStoreEvents,
	PublishableVideoUserEvents
} from '../enums/publishable-events.enum.js';
import { AdsParams } from '../interfaces/ads-params.interface.js';
import {
	PlayerErrorEventData,
	PlayerEventData
} from '../interfaces/player-event-data.interface.js';
import { PlayerOptions } from '../interfaces/player-options.interface.js';
import PubSub from 'pubsub-js';
import { afUserAgent } from 'src/utils/detect-device.util.js';
import { afAddEvent } from 'src/utils/events.util.js';

const logger = new VideoConsoleLogger();

type PlayerEvent =
	| PublishableVideoPlayerEvents
	| PublishableVideoContentEvents
	| PublishableVideoUserEvents
	| PublishableVideoLogEvents
	| PublishableVideoAdEvents
	| PublishableVideoStoreEvents
	| PublishableVideoStickyEvents;

export default abstract class Player {
	public id: string;
	public name: string;
	public aspectRatio: AspectRatio;
	public muted: boolean;
	public videoId: string;
	public videoTitle: string;
	public videoDuration: number;
	public isMobile: boolean;
	public ready = false;
	public videoStarted = false;
	public isAdDisabled = false;
	public isSticked = false;
	public videosPlayed: VideoInfos[] = [];
	public videoInfos$ = new Subject<VideoInfos>();

	protected _readyCb: ((...args: any[]) => any) | null;
	protected _userUnmuted = false;
	private _aufIsMobile = afUserAgent.isMobile;
	private _aufAddEvent = afAddEvent;

	abstract api: HTMLMediaElement | any;

	constructor(options: PlayerOptions) {
		if (
			!options ||
			!options.id ||
			!options.name ||
			!options.aspectRatio ||
			typeof options.muted === 'undefined' ||
			!options.videoId ||
			!options.title
		) {
			throw new Error('Missing mandatory parameter to initialize player');
		}

		this.id = options.id;
		this.name = options.name;
		this.aspectRatio = options.aspectRatio;
		this.muted = options.muted;
		this.videoId = options.videoId;
		this.videoTitle = options.title || 'none';
		this.isAdDisabled = options.isAdDisabled || false;
		this.isMobile = this._aufIsMobile(); // TODO: Créer un d.ts
		this._readyCb = null;

		PubSub.subscribe(PublishableVideoAdEvents.AD_CANCEL, () => {
			this.disableAds();
		});
	}

	public async init(
		adsParams?: AdsParams,
		readyCb?: (...args: any[]) => Promise<any>
	): Promise<void> {
		this._readyCb = readyCb && typeof readyCb === 'function' ? readyCb : null;

		try {
			this.initListeners();
			await this.initApi(adsParams);
			this.initApiListeners();
			this.initErrorsListeners();
		} catch (error) {
			logger.error(error)();
			return Promise.reject(error);
		}

		return Promise.resolve();
	}

	public publish = (
		message: PlayerEvent | string,
		data: PlayerEventData | PlayerErrorEventData = {}
	) => {
		PubSub.publish(message, { ...data, id: this.id });

		const blacklistedFromConsoleLogs = [
			'video.player.content.progress',
			'video.player.inProgress'
		];

		if (blacklistedFromConsoleLogs.includes(message)) return;
		logger.debug(`Publishing ${message}`)();
	};

	public logError = (message: string) =>
		this.publish(PublishableVideoLogEvents.ERROR, {
			message,
			category: this.name
		});

	public cancelAdvertising = () =>
		this.publish(PublishableVideoAdEvents.AD_CANCEL);

	public isPlaying = async () => {
		const isPaused = await this.isPaused();
		return !isPaused;
	};

	public getWidth = () => this.getContainer()?.getBoundingClientRect().width;
	public getHeight = () => this.getContainer()?.getBoundingClientRect().height;

	// -= Playback functions =-
	public async play() {
		if (await this.isPlaying()) return;

		const successHandler = () => true;
		const errorHandler = (error: Error) => {
			if (error) {
				this.logError(error.message);
			}

			return false;
		};

		let play = (this.api as HTMLMediaElement).play();

		return !(play && typeof play.then === 'function')
			? play
			: play.then(successHandler, errorHandler);
	}

	public pause() {
		return this.api.pause();
	}

	protected async onReady() {
		if (this.ready) return;
		this.publish(PublishableVideoPlayerEvents.READY);

		// Send video complete event with completion rate when user closes the tab
		this._aufAddEvent(
			window,
			'beforeunload',
			() => this.publish(PublishableVideoPlayerEvents.END),
			false
		);

		if (this._readyCb && typeof this._readyCb === 'function') {
			await this._readyCb();
		}

		this.ready = true;
		this.postReady();
		return Promise.resolve();
	}

	protected onPlay() {
		this.prePlay();

		this.videoStarted = true;
		this.publish(PublishableVideoPlayerEvents.PLAY);

		this.postPlay();
	}

	protected initCommonListeners() {
		this.api.on('ready', async () => await this.onReady());
		this.api.on('play', this.onPlay.bind(this));
		this.api.on('pause', this.onPause.bind(this));
	}

	protected onPause = () => this.publish(PublishableVideoPlayerEvents.PAUSE);

	abstract isPaused(): boolean | Promise<boolean>;
	abstract getContainer(): HTMLElement | Element | null;

	protected abstract initApi(adsParams?: AdsParams): Promise<void>;
	protected abstract initListeners(): void;
	protected abstract initApiListeners(): void;
	protected abstract initErrorsListeners(): void;
	protected abstract prePlay(): void;
	protected abstract postPlay(): void;
	protected abstract postReady(): void;
	protected abstract disableAds(): void;
}

export class StickyBaseElement {
	el: HTMLElement;

	constructor(el: HTMLElement, className?: string) {
		this.el = el;
		this.el.className = className ?? '';
	}

	hide() {
		this.el.hidden = true;
	}

	show() {
		this.el.hidden = false;
	}
}

export const getClassname = (
	classList: string[] = [],
	customClassName: string | string[] = ''
): string => {
	const customClassList =
		typeof customClassName === 'string' ? [customClassName] : customClassName;

	const toMerge = customClassList
		? [...classList, ...customClassList]
		: [...classList];

	return toMerge.join(' ').trim();
};

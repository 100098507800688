import { DailymotionEmbedPlayer } from 'src/players/dailymotion-embed';
import {
	PublishableVideoAdEvents,
	PublishableVideoPlayerEvents,
	PublishableVideoStickyEvents
} from '../enums/publishable-events.enum';
import { ListenerAction } from '../interfaces/listener-action';
import AdPlayer, { AdPlayerOptions } from './_adapter';
import PubSub from 'pubsub-js';

export interface DailymotionAdPlayerOptions extends AdPlayerOptions {
	playPreroll: boolean;
}

export default class DailymotionEmbedAdPlayer extends AdPlayer {
	private dailymotion = window.dailymotion;

	private _apiListenersActions: ListenerAction[] = [
		{
			message: this.dailymotion.events.AD_START,
			actionFn: () => {
				this._emitEventHandler(
					PublishableVideoAdEvents.AD_START,
					this._getAdData({})
				);
				this.publish(PublishableVideoStickyEvents.STICKY_CONTENT_START);
			}
		},
		{
			message: this.dailymotion.events.AD_END,
			actionFn: () =>
				this._emitEventHandler(
					PublishableVideoAdEvents.AD_END,
					this._getAdData({})
				)
		},
		{
			message: this.dailymotion.events.AD_IMPRESSION,
			actionFn: () =>
				this._emitEventHandler(
					PublishableVideoAdEvents.AD_IMPRESSION,
					this._getAdData({})
				)
		},
		{
			message: this.dailymotion.events.AD_PAUSE,
			actionFn: () =>
				this._emitEventHandler(
					PublishableVideoAdEvents.AD_PAUSE,
					this._getAdData({})
				)
		},
		{
			message: this.dailymotion.events.AD_PLAY,
			actionFn: () => {
				this._emitEventHandler(
					PublishableVideoAdEvents.AD_PLAY,
					this._getAdData({})
				);
				this._emitEventHandler(
					PublishableVideoAdEvents.AD_IMPRESSION,
					this._getAdData({})
				);
			}
		},
		{
			message: this.dailymotion.events.AD_TIMECHANGE,
			actionFn: () =>
				this._emitEventHandler(PublishableVideoAdEvents.AD_TIMEUPDATE)
		},
		{
			message: this.dailymotion.events.AD_COMPANIONSREADY,
			actionFn: () =>
				this._emitEventHandler(PublishableVideoAdEvents.AD_COMPANIONS)
		},
		{
			message: this.dailymotion.events.AD_DURATIONCHANGE,
			actionFn: () =>
				this._emitEventHandler(PublishableVideoAdEvents.AD_DURATIONCHANGE)
		},
		{
			message: this.dailymotion.events.AD_CLICK,
			actionFn: () => this._emitEventHandler(PublishableVideoAdEvents.AD_CLICK)
		},
		{
			message: this.dailymotion.events.AD_LOADED,
			actionFn: () =>
				this._emitEventHandler(
					PublishableVideoAdEvents.AD_LOADED,
					this._getAdData({})
				)
		},
		{
			message: this.dailymotion.events.PLAYER_HEAVYADSINTERVENTION,
			actionFn: (report) => {
				console.error('HEAVY_ADS_REPORT', report);
				this._emitEventHandler(PublishableVideoAdEvents.HEAVY_ADS, report);
			}
		}
	];

	constructor(options: DailymotionAdPlayerOptions) {
		super({ ...options, name: 'dmp-embed-ads' });
	}

	protected async _postInit(): Promise<void> {
		PubSub.subscribe(
			PublishableVideoPlayerEvents.READY,
			this.onApiLoaded.bind(this)
		);
		return Promise.resolve();
	}

	protected _postPlayAdInstant = () => {}; // Do Nothing here

	protected _initApiListeners() {
		this.dailymotion.getPlayer(this._player.id).then((player) => {
			this._apiListenersActions.forEach((listener) =>
				player.on(listener.message, listener.actionFn)
			);
		});
	}

	protected _initErrorsListeners = () => {}; // Do Nothing here

	/**
	 * Emet un evenement
	 * @param evt Mon event
	 * @param ad Mon ad
	 */
	private _emitEventHandler(evt: PublishableVideoAdEvents, ad?: any): void {
		this._setAdsState(evt);
		this.publishApiEvent(evt, ad);
	}

	// @Dam: TODO: Typer correctement cette methode
	protected _getAdData = (ad: any) =>
		this._player.api && (this._player.api as any).adData
			? (this._player.api as any).adData
			: null;

	protected _getVideoBreak = (ad: any) =>
		(this._player as DailymotionEmbedPlayer).getRollPosition();

	public play = () => (this._ready ? this._player.api.play() : undefined);
	public pause = () => (this._ready ? this._player.api.pause() : undefined);
	public mute = () =>
		this._ready ? (this._player as DailymotionEmbedPlayer).mute() : undefined;
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.DailymotionEmbedAdPlayer = DailymotionEmbedAdPlayer;

/* globals XDomainRequest */
var Aflog = window.Aflog || {};

// TODO: replace with a proper array queue to remove dependancy with afLogger object:
// - before: Aflog.cmd.push(function() { afLogger.logConsole('async log'); } ):
// - after:  Aflog('logConsole', 'async log');
Aflog.Queue = (function () {
  var queue = function (cmd) {
    this.push(cmd);
  };

  queue.prototype = {
    constructor: queue,
    push: function () {
      for (var i = 0; i < arguments.length; i++) {
        try {
          if (typeof arguments[i] === 'function') {
            arguments[i]();
          }
        } catch (e) {
          if (window.afLogger) {
            afLogger.logError('Aflog command queue error: ' + e);
          }
        }
      }
    }
  };

  return queue;
})();

Aflog.Logger =
  Aflog.Logger ||
  (function () {
    var BEACON_URL = 'https://pixels.afcdn.com/logpix.php'; // Logging beacon URL for all Unify sites
    var LEGACY_ERROR_PIXEL_PATH = '/reloaded/errpix.php'; // Legacy pixel (PHP stats&logs)

    var CHANNEL = 'JS Logger';

    var EXTERNAL_DOMAINS_REGEXP =
      /onmeda|netmums|itsrosy|livingly|lonny|mabelandmoxie|stylebistro|zimbio|lesnumeriques|cnetfrance|beaute-test|gamekult|zdnet|programmez|paroledemamans|avisdemamans|paroledesagesfemmes/i;
    var EXTERNAL_DOMAINS_REGEXP_LEGACY = /doctissimo/i;

    var SESSIONS_SERVICE_NAME = 'session'; // Service name used in dataviz tool
    var SESSIONS_SAMPLE_RATE = 10; // In percent of all events, set false to disable sampling and log everything

    var ERRORS_SERVICE_NAME = 'statslogs'; // Service name used in dataviz tool
    var ERRORS_SAMPLE_RATE = 10; // Default error rate, in percent of all JS errors

    var EVENTS_SAMPLE_RATE = 100; // Default sample rate, in percent of all JS events

    // Cloud Functions + BigQuery logging
    var REALTIME_SAMPLE_RATE = 0;
    var REALTIME_ENDPOINT =
      'https://europe-west1-realtime-logging-228816.cloudfunctions.net/realtime-logs';
    var REALTIME_MAXQUEUE = 50;
    var REALTIME_TIMEOUT = 10000; // ms

    // TODO: move to HB logger!
    var REALTIME_LOGGING_HEADER_BIDDING_MAPPING = {
      // GLOBAL data
      content_type: 'datalayer.pageInfo.contentType',
      site: 'datalayer.siteInfo.platform',
      segment: 'datalayer.userInfo.segment',
      device: 'datalayer.userInfo.device',
      browser: '#UA_BROWSER#',
      browser_version: '#UA_BROWSER_VER#',
      os: '#UA_OS#',
      os_version: '#UA_OS_VER#',

      // HB Data
      account: 'headerBidding.account',
      adserver: 'headerBidding.adserver',
      bidder: 'headerBidding.bidder',
      cpm: 'headerBidding.cpm',
      format: 'headerBidding.format',
      size: 'headerBidding.size',
      target: 'headerBidding.target',

      // New Prebid Fields
      ad_unit_path: 'headerBidding.ad_unit_path',
      line_item_id: 'headerBidding.line_item_id',
      campaign_id: 'headerBidding.campaign_id',
      creative_id: 'headerBidding.creative_id'
    };

    var logger = function (options) {
      this.init(options);
    };

    let sessionDataDisabled;

    logger.prototype = {
      verbose: {},
      context: {},

      init: function (options) {
        // Init datalayer if any, otherwise try to fallback to Unify global datalayer
        this.datalayer =
          options && options.datalayer
            ? options.datalayer
            : window.af_dataLayer
            ? af_dataLayer[0]
            : null;

        sessionDataDisabled = options ? options.disableSessionData : false;

        this.initCommandQueue();
        this.initContext(options);
        this.initSessionsLogger();

        window.onbeforeunload = function () {
          this.sendLogsToBigQuery();
          return;
        }.bind(this);
      },

      // Replace command queue array with function calls
      initCommandQueue: function () {
        Aflog.cmd = new Aflog.Queue(Aflog.cmd);
      },

      initContext: function (options) {
        // Init global context if available in options
        if (options && options.context) {
          this.context = options.context;
        }

        // Retrieve basic informations about user network
        var connection = navigator.connection || {};
        this.context.network = {
          downlink: connection.downlink,
          effectiveType: connection.effectiveType,
          rtt: connection.rtt,
          saveData: connection.saveData
        };

        // Retrieve basic informations about browser & OS
        var bowser = window.bowser;
        if (!bowser) {
          return;
        }

        this.context.browser = getBowserContext(bowser);

        // TODO: useless, check with money team to remove it
        this.realtimeLoggingPlaceholders = {
          '#UA_BROWSER#': this.context.browser.name,
          '#UA_BROWSER_VER#': this.context.browser.version,
          '#UA_OS#': this.context.browser.osName,
          '#UA_OS_VER#': this.context.browser.osVersion
        };

        /***
         * @param d Bowser informations
         * @return Context informations from Bowser
         */
        function getBowserContext(d) {
          if (!d && !d.name && !d.browser) {
            console.error(
              '[Analytics] [VIDEO] Bowser package is not loaded !!'
            );
            return;
          }

          const browserInformations = {
            name: d.name || d.browser?.name,
            version: d.version || d.browser?.version
          };

          const osInformations = {
            osName: d.osname || d.os?.name,
            osVersion: d.osversion || d.os?.version
          };

          return {
            ...browserInformations,
            ...osInformations
          };
        }
      },
      addContext: function (data) {
        // In case Bowser is from NPM (Videoplayer)
        if (!this.context.browser) {
          // Reinit context as this script is IIFE so exectuted at the import time before bowser is executed
          this.initContext();
        }

        data = data || {};
        data.datalayer = data.datalayer || this.getFilteredDatalayer();
        data.session =
          data.session || !sessionDataDisabled
            ? this.getSessionData()
            : undefined;
        data.browser = this.context.browser;
        data.network = this.context.network;

        return data;
      },

      setVerbose: function (service, enabled) {
        if (!service) {
          this.logError('Failed activating verbose - missing service name');
          return;
        }

        var key = service.toLowerCase() + '_verbose';
        this.verbose[service] = enabled || this.getConfig(key) ? '1' : '0';
        localStorage.setItem(key, this.verbose[service]);
      },

      setDataLayer: function (datalayer) {
        this.datalayer = datalayer;
      },

      logConsole: function (logs, format, service, error) {
        if (!window.console) {
          return;
        }

        // Only display console logs when verbose mode is enable for current service
        if (service && !this.getConfig(service.toLowerCase() + '_verbose')) {
          return;
        }

        // Adjust display format according to log type
        format = format || 'log';
        if (typeof logs === 'object' && !Array.isArray(logs)) {
          format = 'table';
        } else if (service) {
          logs = [].concat('[' + service + '] ', logs);
        }

        // Log in console
        if (format === 'table') {
          console.table(logs);
        } else if (format === 'count') {
          console.count(logs);
        } else if (format === 'error' && error) {
          console.error(error);
        } else {
          console[format].apply(console, [].concat(logs));
        }
      },

      logError: function (message, pool, consoleOnly, data, error) {
        // Classic log in console
        // TODO: replace with custom decorate() function
        var prefix = pool ? '[' + pool + '] ' : '';

        this.logConsole(prefix + message, 'error', undefined, error);

        // Sampling to reduce costs
        var sampleRate =
          data && data.sampleRate !== undefined
            ? data.sampleRate
            : ERRORS_SAMPLE_RATE;
        if (
          consoleOnly ||
          (sampleRate && sampleRate < Math.floor(Math.random() * 100))
        ) {
          return;
        }

        // Add useful informations to error logs
        var data = data || {};
        data.pool = pool || 'JS';
        data.coef = Math.floor(100 / sampleRate); // Multiplier, used for dataviz tool
        data.revision = this.context.revision || 'N/A';
        data.stacktrace = data.stacktrace || 'N/A';
        data = this.addContext(data);

        // Send log to legacy stats&logs pixel (old way)
        // TODO: remove and log in stats&logs via the new pixel
        var reversedMessage = message.split('').reverse().join(''); // Prevent from being catched by SQL anti-injection
        var stackMessage = data.stacktrace.split('').reverse().join(''); // Prevent from being catched by SQL anti-injection
        var url =
          this.getLogPixelDomain() +
          LEGACY_ERROR_PIXEL_PATH +
          '?s=' +
          data.coef + // sampling
          '&d=' +
          encodeURIComponent(reversedMessage) +
          '&st=' +
          encodeURIComponent(stackMessage) +
          '&t=' +
          Math.round(Math.random() * 99999) + // random timestamp to prevent browser caching
          (pool ? '&c=' + encodeURIComponent(data.pool) : '');
        this.sendBeacon(url);

        // Send log to global pixel
        this.sendLogToPixel(ERRORS_SERVICE_NAME, message, data);
      },
      logErrorWithContext: function (message, pool, data) {
        this.logError(message, pool, false, data);
      },

      logEvent: function (service, message, data) {
        if (!service) {
          this.logError('Log event without specifying service');
        }

        // Add context informations to event logs
        data = data || {};
        data = this.addContext(data);

        // Add multiplier coef, for proper dataviz
        var sampleRate =
          data.sampleRate !== undefined ? data.sampleRate : EVENTS_SAMPLE_RATE;
        data.coef = Math.floor(100 / sampleRate);

        // Always log events in console for debugging
        this.logConsole([message, data], 'log', service);

        // Send beacon to logging pixel endpoint with sampling, to reduce costs
        var isSampled =
          (data && data.sampled) ||
          Math.floor(Math.random() * 100) > EVENTS_SAMPLE_RATE;
        if (!isSampled) {
          this.sendLogToPixel(service, message, data);
        }
      },

      // Format and send logs to the queue
      // TODO: mutualize with logEvent and add a "sync" flag to handle bulk mode
      logAnalytics: function (service, logs, applySampling) {
        // Arrify logs
        if (!(logs instanceof Array)) {
          logs = [logs];
        }

        if (logs.length < 1) {
          return;
        }

        var datalayer = this.getFilteredDatalayer();
        var sessionData = !sessionDataDisabled
          ? this.getSessionData()
          : undefined;

        for (var i = 0, l = logs.length; i < l; i++) {
          logs[i].sessionData = sessionData;
          logs[i].datalayer = datalayer;
          logs[i].url = window.location.href;
        }

        this.addLogsToQueue(service, logs, applySampling);
      },

      /*
       * Gets the current data for the current session for the user
       */
      getSessionData: function () {
        if (!window.afSession) {
          this.logError('Failed retrieving session data');
          return;
        }

        return {
          visitor: afSession.getVisitorId(),
          session: afSession.getSessionId(),
          pageview: afSession.getPageviewId(),
          wave: afSession.getWaveId(),
          rank: afSession.getRankId()
        };
      },

      getFilteredDatalayer: function () {
        // Fallback for sites without datalayer
        if (!this.datalayer) {
          return {
            siteInfo: {
              platform: document.domain.replace('www.', '').replace('m.', '')
            }
          };
        }

        var filteredDatalayer = {};
        if (this.datalayer.userInfo || this.datalayer.user) {
          var key = this.datalayer.userInfo ? 'userInfo' : 'user';

          filteredDatalayer.userInfo = {
            du: this.datalayer[key].du || this.datalayer[key].dom,
            device: this.datalayer[key].device,
            adblocker: this.datalayer[key].adblocker ? true : false,
            screenResolution: this.datalayer[key].screenResolution,
            segment: this.datalayer[key].segment
          };
          filteredDatalayer.vuid =
            this.datalayer[key].vuid || this.datalayer[key].id;
        }
        if (
          this.datalayer.pageInfo ||
          (this.datalayer.page && this.datalayer.page)
        ) {
          var key = this.datalayer.userInfo ? 'pageInfo' : 'page';

          filteredDatalayer[key] = {
            version: this.datalayer[key].version || null,
            contentType:
              this.datalayer[key].contentType || this.datalayer.content.type,
            section: this.datalayer[key].section,
            subsection:
              this.datalayer[key].subsection || this.datalayer[key].subSection,
            tag: this.datalayer[key].tag || this.datalayer[key].mainTag,
            category: this.datalayer[key].category || null,
            adstack: this.datalayer[key].adstack || null
          };
        }
        if (this.datalayer.campaignInfo || this.datalayer.campaignInfo) {
          var key = this.datalayer.userInfo ? 'campaignInfo' : 'utm';

          filteredDatalayer.campaignInfo = {
            utmSource:
              this.datalayer[key].utmSource || this.datalayer[key].source,
            utmCampaign:
              this.datalayer[key].utmCampaign || this.datalayer[key].campaign,
            utmMedium:
              this.datalayer[key].utmMedium || this.datalayer[key].medium,
            paid:
              this.datalayer[key].paid ||
              ['cpc', 'cpc-vertical', 'cpc-horizontal'].includes(
                this.datalayer[key].medium
              )
          };
        }
        if (this.datalayer.siteInfo || this.datalayer.site) {
          var key = this.datalayer.siteInfo ? 'siteInfo' : 'site';

          var isMobile = null;
          if (this.datalayer[key].isMobile !== 'undefined') {
            isMobile = this.datalayer[key].isMobile;
          } else if (filteredDatalayer.userInfo) {
            isMobile = filteredDatalayer.userInfo !== 'desktop' ? 1 : 0;
          }

          filteredDatalayer.siteInfo = {
            platform: this.datalayer[key].platform,
            isMobile: isMobile
          };
        }

        return filteredDatalayer;
      },

      // === COMMON LOGGER FOR SESSIONS ===

      initSessionsLogger: function () {
        // Only activate on sites with PubSub lib
        if (!window.PubSub) {
          return;
        }

        var that = this;

        // Send logs on each pageview ..
        PubSub.subscribe('datalayer.ready', function () {
          that.logSessions();
        });

        // .. and also each AJAX pageview (albums, games, etc.)
        PubSub.subscribe('tracking.call', function () {
          that.logSessions();
        });
      },

      logSessions: function () {
        if (
          SESSIONS_SAMPLE_RATE &&
          Math.floor(Math.random() * 100) > SESSIONS_SAMPLE_RATE
        ) {
          return;
        }

        this.logEvent(SESSIONS_SERVICE_NAME, 'pageview');
      },

      // === REAL-TIME LOGGING with Unify pixel ===

      sendLogToPixel: function (service, message, data) {
        var payload = JSON.stringify({
          service_name: service,
          channel: CHANNEL,
          message: message,
          data: data
        });
        this.sendBeacon(BEACON_URL, payload);
      },

      getLogPixelDomain: function () {
        var domain = location.hostname;

        // Remove subdomain if any
        var hostnameArr = location.hostname.split('.');
        if (hostnameArr.length > 2) {
          hostnameArr.shift();
          domain = hostnameArr.join('.');
        }

        // Add subdomain for log pixel
        if (EXTERNAL_DOMAINS_REGEXP.test(location.hostname)) {
          domain = 'https://aufeminin.' + domain;
        } else if (EXTERNAL_DOMAINS_REGEXP_LEGACY.test(location.hostname)) {
          domain = 'https://dfp.' + domain; // TODO: replace with standard domain name (used only by Doctissimo)
        } else if (location.hostname.indexOf('local.') >= 0) {
          domain = 'https://local.' + domain;
        } else {
          domain = 'https://www.' + domain;
        }

        return domain;
      },

      // === REAL-TIME LOGGING with Google Cloud Functions + BigQuery ===

      realtimeLogs: {},
      realtimeTimer: null,
      realtimeLogTime: null,
      realtimeLoggingMapping: {
        HEADER_BIDDING: REALTIME_LOGGING_HEADER_BIDDING_MAPPING,
        HEADER_BIDDING_BOOKED: REALTIME_LOGGING_HEADER_BIDDING_MAPPING,
        HEADER_BIDDING_RENDER: REALTIME_LOGGING_HEADER_BIDDING_MAPPING
      },

      addLogsToQueue: function (service, logs, applySampling) {
        // Sampling to reduce costs
        if (
          applySampling &&
          REALTIME_SAMPLE_RATE &&
          Math.floor(Math.random() * 100) > REALTIME_SAMPLE_RATE
        ) {
          return;
        }

        for (var i = 0, l = logs.length; i < l; i++) {
          var formattedLog = this.getFormattedRealtimeLog(service, logs[i]);
          if (typeof formattedLog !== 'undefined') {
            if (!this.realtimeLogs.hasOwnProperty(service)) {
              this.realtimeLogs[service] = [];
            }
            this.realtimeLogs[service].push(formattedLog);
          }
        }

        var nbLogs = 0;
        for (var type in this.realtimeLogs) {
          nbLogs += this.realtimeLogs[type].length;
        }

        if (nbLogs > REALTIME_MAXQUEUE) {
          this.sendLogsToBigQuery();
        } else {
          var that = this;
          this.realtimeTimer = window.setTimeout(function () {
            that.sendLogsToBigQuery();
          }, REALTIME_TIMEOUT);
        }
      },

      // Pyramid of doom spotted!
      // TODO: rework to avoid nested if statements
      getFormattedRealtimeLog: function (service, log) {
        var formattedLog = {};

        if (this.realtimeLoggingMapping.hasOwnProperty(service)) {
          var mapping = this.realtimeLoggingMapping[service];

          for (var key in mapping) {
            if (mapping.hasOwnProperty(key)) {
              var value = mapping[key];
              if (this.realtimeLoggingPlaceholders.hasOwnProperty(value)) {
                formattedLog[key] = this.realtimeLoggingPlaceholders[value];
              } else {
                var path = value.split('.');
                for (var i = 0; i < path.length; i++) {
                  if (
                    formattedLog.hasOwnProperty(key) &&
                    formattedLog[key].hasOwnProperty(path[i])
                  ) {
                    formattedLog[key] = formattedLog[key][path[i]];
                  } else if (
                    !formattedLog.hasOwnProperty(key) &&
                    log.hasOwnProperty(path[i])
                  ) {
                    formattedLog[key] = log[path[i]];
                  } else {
                    formattedLog[key] = null;
                    break;
                  }
                }
              }
            }
          }
        } else {
          return;
        }

        return formattedLog;
      },

      sendLogsToBigQuery: function () {
        var data = [];

        for (var service in this.realtimeLogs) {
          if (this.realtimeLogs[service].length > 0) {
            data.push({
              logtype: service,
              logs: this.realtimeLogs[service]
            });
          }
        }

        this.realtimeLogs = {};
        window.clearTimeout(this.realtimeTimer);

        if (data.length > 0) {
          this.logConsole(['real-time bulk logging', data], '', 'realtime');

          var headers = [{ header: 'Content-Type', value: 'application/json' }];

          // TODO: replace XHR request with sendBeacon (check with money team first)
          // var blob = new Blob([JSON.stringify(data)], headers);
          // return this.sendBeacon(REALTIME_ENDPOINT, blob);
          return this.sendXhrRequest('POST', REALTIME_ENDPOINT, data, headers);
        }
      },

      // === UTILS FUNCTIONS ===

      getConfig: function (key) {
        if (!this.hashs) {
          this.hashs = this.getLocationHash();
        }

        return (
          this.hashs[key] ||
          (window.localStorage && localStorage.getItem(key)) ||
          ''
        );
      },

      sendBeacon: function (url, data) {
        if (navigator.sendBeacon) {
          navigator.sendBeacon(url, data);
        } else {
          // Fallback to synchronous request for old browsers, bad because it blocks next page loading ..
          this.sendXhrRequest('POST', url, data);
        }
      },

      sendXhrRequest: function (method, url, payload, headers) {
        var request = window.XDomainRequest
          ? new XDomainRequest()
          : new XMLHttpRequest();
        request.open(method, url, true);

        if (!window.XDomainRequest && headers && headers.length) {
          for (var i = 0, l = headers.length; i < l; i++) {
            request.setRequestHeader(headers[i].header, headers[i].value);
          }
        }

        request.onerror = function (response) {
          this.logError('Error while sending XHR request to ' + url);
        };

        var body = JSON.stringify(payload);

        return request.send(body);
      },

      /*
       * List location.hash parameters into an object.
       * TODO: move to global utils object
       */
      getLocationHash: function () {
        var hashParams = {};
        var e,
          a = /\+/g, // Regex for replacing addition symbol with a space
          r = /([^&;=]+)=?([^&;]*)/g,
          d = function (s) {
            return decodeURIComponent(s.replace(a, ' '));
          },
          q = window.location.hash.substring(1);

        while ((e = r.exec(q))) {
          hashParams[d(e[1])] = d(e[2]);
        }

        return hashParams;
      }
    };

    return logger;
  })();

window.module = {};
module.exports = Aflog.Logger;
window.module = undefined;

export enum DailymotionInternalEvents {
	API_READY = 'apiready',
	CONTROLS_CHANGE = 'controlschange',
	START = 'start',
	END = 'end',
	ENDED = 'ended',
	ERROR = 'error',
	FULLSCREEN_CHANGE = 'fullscreenchange',
	PLAYBACK_READY = 'playback_ready',
	SEEKING = 'seeking',
	SEEKED = 'seeked',
	VIDEO_CHANGE = 'videochange',
	VOLUME_CHANGE = 'volumechange',
    VIDEO_START = 'video_start',
    VIDEO_END = 'video_end',
    PAUSE = 'pause',
    PLAY = 'play',
    PLAYING = 'playing',
    DURATION_CHANGE = 'durationchange',
    LOADED_METADATA = 'loadedmetadata',
    WAITING = 'waiting',
    SUBTITLE_CHANGE = 'subtitlechange',
    SUBTITLES_AVAILABLE = 'subtitlesavailable',
    QUALITIES_AVAILABLE = 'qualitiesavailable',
    QUALITIES_CHANGED = 'qualitieschanged',
    TIMEUPDATE = 'timeupdate',
    PROGRESS = 'progress',
    PLAYBACK_RESOLUTION = 'playback_resolution',
    AD_START = 'ad_start',
    AD_END = 'ad_end',
    AD_PAUSE = 'ad_pause',
    AD_PLAY = 'ad_play',
    AD_TIMEUPDATE = 'ad_timeupdate',
    AD_COMPANIONS = 'ad_companions',
    AD_CLICK = 'ad_click',
    AD_LOADED = 'ad_loaded',
    AD_IMPRESSION = 'ad_impression',
    AD_BUFFERSTART = 'ad_bufferStart',
    AD_BUFFERFINISH = 'ad_bufferFinish',
	PLAYER_HEAVYADSINTERVENTION = 'PLAYER_HEAVYADSINTERVENTION' // https://faq.dailymotion.com/hc/fr/articles/4414693854738-G%C3%A9rer-l-int%C3%A9gration-du-player-d%C3%A9tect%C3%A9-par-Heavy-Ad
}

export enum DailymotionEmbedInternalEvents {
	PLAYER_ASPECTRATIOCHANGE = 'PLAYER_ASPECTRATIOCHANGE',
	PLAYER_CONTROLSCHANGE = 'PLAYER_CONTROLSCHANGE',
	PLAYER_CRITICALPATHREADY = 'PLAYER_CRITICALPATHREADY',
	PLAYER_END = 'PLAYER_END',
	PLAYER_ERROR = 'PLAYER_ERROR',
	PLAYER_PIPEXPANDEDCHANGE = 'PLAYER_PIPEXPANDEDCHANGE',
	PLAYER_PLAYBACKPERMISSION = 'PLAYER_PLAYBACKPERMISSION',
	PLAYER_PRESENTATIONMODECHANGE = 'PLAYER_PRESENTATIONMODECHANGE',
	PLAYER_SCALEMODECHANGE = 'PLAYER_SCALEMODECHANGE',
	PLAYER_START = 'PLAYER_START',
	PLAYER_VIDEOCHANGE = 'PLAYER_VIDEOCHANGE',
	PLAYER_VIEWABILITYCHANGE = 'PLAYER_VIEWABILITYCHANGE',
	PLAYER_VOLUMECHANGE = 'PLAYER_VOLUMECHANGE',
	PLAYER_HEAVYADSINTERVENTION = 'PLAYER_HEAVYADSINTERVENTION',
	VIDEO_BUFFERING = 'VIDEO_BUFFERING',
	VIDEO_DURATIONCHANGE = 'VIDEO_DURATIONCHANGE',
	VIDEO_END = 'VIDEO_END',
	VIDEO_PAUSE = 'VIDEO_PAUSE',
	VIDEO_PLAY = 'VIDEO_PLAY',
	VIDEO_PLAYING = 'VIDEO_PLAYING',
	VIDEO_PROGRESS = 'VIDEO_PROGRESS',
	VIDEO_QUALITIESREADY = 'VIDEO_QUALITIESREADY',
	VIDEO_QUALITYCHANGE = 'VIDEO_QUALITYCHANGE',
	VIDEO_SEEKEND = 'VIDEO_SEEKEND',
	VIDEO_SEEKSTART = 'VIDEO_SEEKSTART',
	VIDEO_SUBTITLESCHANGE = 'VIDEO_SUBTITLESCHANGE',
	VIDEO_SUBTITLESREADY = 'VIDEO_SUBTITLESREADY',
	VIDEO_START = 'VIDEO_START',
	VIDEO_TIMECHANGE = 'VIDEO_TIMECHANGE',
	AD_COMPANIONSREADY = 'AD_COMPANIONSREADY',
	AD_DURATIONCHANGE = 'AD_DURATIONCHANGE',
	AD_END = 'AD_END',
	AD_IMPRESSION = 'AD_IMPRESSION',
	AD_LOADED = 'AD_LOADED',
	AD_PAUSE = 'AD_PAUSE',
	AD_PLAY = 'AD_PLAY',
	AD_START = 'AD_START',
	AD_TIMECHANGE = 'AD_TIMECHANGE',
	AD_CLICK = 'AD_CLICK'
}

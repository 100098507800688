import {
	PublishableVideoAdEvents,
	PublishableVideoLogEvents
} from 'src/enums/publishable-events.enum';
import { Manager } from 'src/manager';
import { Store } from 'src/store';
import { AufVideoUtils } from 'src/utils/utils';
import { VideoConsoleLogger } from 'src/utils/video-logger';
import { PlayerPosition } from '../enums/player-position.enum';
import { PlayerInstance } from '../types/player-instance.type';
import PubSub from 'pubsub-js';
import {
	AdUnitCode,
	PegasusAmazonItem,
	PegasusPreBidItem,
	PegasusResponseBids
} from 'src/interfaces/pegasus-response-bids.interface';

const logger = new VideoConsoleLogger();

let SETTINGS: any = {
	DEFAULTplayer_WIDTH: 640,
	DEFAULTplayer_HEIGHT: 480,
	HB_VIDEO_TIMEOUT: 3000,
	DEFAULT_AD_TAG_URL:
		'https://securepubads.g.doubleclick.net/gampad/ads' +
		'?sz=640x480' + // Size of master video ad slot, use pipe for multiple sizes
		'&impl=s' + // The request mode. Here, s for sync
		'&gdfp_req=1' + // Indicates that the user is on the DFP schema
		'&env=vp' + // Indicates that the request is from a video player
		'&output=vast' + // Output format of ad, vast => network's default setting
		'&unviewed_position_start=1', // Setting this to 1 turns on delayed impressions,
	CMP_AD_SERVER_KEY: 'google'
};

SETTINGS.DEBUG_AD_TAG_URL =
	SETTINGS.DEFAULT_AD_TAG_URL +
	'&iu=/124319096/external/single_ad_samples' +
	'&ciu_szs=300x250' +
	'&cust_params=deployment%3Ddevsite%26sample_ct%3Dskippablelinear';

export interface AdPlayerOptions {
	id: string;
	name: string;
	player: PlayerInstance;
	adUnit: string;
	adTargeting: any; // TODO: Type that
	allowMultipleTinyAds: boolean;
	siteId: number;
	position: PlayerPosition;
	adScheduleId: string;
	playPreroll: boolean;
	needPegasus: boolean;
	hbVideoActive?: boolean;
	jwpAdvertisingParameters?: boolean;
	isCM?: boolean;
	adRule?: boolean;
	viewableVideo?: boolean;
}

export interface AdData {}

export interface AdState {
	playing: boolean;
	started: boolean;
	ended: boolean;
	paused: boolean;
	pods: number;
	eventsLists: string[];
}

declare global {
	interface Window {
		Didomi: any;
		unify_dataSlayer: any;
		afAdblock: any;
		pegasusConf: any;
		sas_target: any;
		pegasus: any;
	}
}

export default abstract class AdPlayer {
	public adsState: AdState = {
		playing: false,
		started: false,
		ended: false,
		paused: false,
		pods: 0,
		eventsLists: []
	};
	private _hbResponses: PegasusResponseBids; // TODO: Type that (if hb still needed)
	private _adTagUrl: string;
	private _debug = false;
	private _allowMultipleTinyAds = false;
	private _adTargeting: any; // TODO: Type that
	private _adRule: boolean;
	private _isCM: boolean;
	protected _jwpAdvertisingParameters: boolean;
	protected _playPreroll: boolean;
	protected _viewableVideo: boolean;
	protected _id: string;
	protected _ready = false;
	protected _player: PlayerInstance;
	protected _hbVideoActive: boolean;
	protected _adScheduleId: string;
	public adUnit: string;
	public position: PlayerPosition;
	public needPegasus: boolean;
	public name: string;
	public siteId: number;

	constructor(options: AdPlayerOptions) {
		this._id = options.id;

		if (!options.id || !options.player || !options.adUnit) {
			this.logError('Unable to init ad player (missing options)');
			return;
		}

		this.name = options.name;
		this.siteId = options.siteId;
		this._player = options.player;
		this.adUnit = options.adUnit;
		this._adTargeting = options.adTargeting;
		this._allowMultipleTinyAds = options.allowMultipleTinyAds;
		this.position = options.position;
		this._adScheduleId = options.adScheduleId;
		this._hbVideoActive = options.hbVideoActive || false;
		this._jwpAdvertisingParameters = options.jwpAdvertisingParameters || false;
		this.needPegasus = options.needPegasus || false;
		this._isCM = options.isCM || false;
		this._playPreroll = options.playPreroll;
		this._adRule = options.adRule || false;
		this._viewableVideo = false;
	}

	public async init(store?: Store, player?: any): Promise<void> {
		this._player = player;

		// If user got an adBlocker or if website uses a CMP and user doesn't consent to ad server, do not init ad player
		const adsDisabled =
			this._userHasAdBlock() ||
			(window.Didomi &&
				!window.Didomi.getUserConsentStatusForVendor(
					SETTINGS.CMP_AD_SERVER_KEY
				));

		if (adsDisabled) return Promise.resolve();

		PubSub.subscribe(
			PublishableVideoAdEvents.AD_TAG_READY,
			(
				event,
				params: { adTagUrl: string; typeAd: string; position: string }
			) => {
				if (params.position !== this.position) return;

				this._playAdInstant(params.adTagUrl);
			}
		);

		await this._postInit(store, player);
		return Promise.resolve();
	}

	// (Ima & Jwplayer) Ad tag: regenerate URL on each request because some key/values are updated on the fly for some 3rd party
	// RECO Damien: Déplacer vers un service plutot que dans l'adplayer
	protected async getAdTagUrl(typeAd: string = 'preroll') {
		if (this._debug) {
			return SETTINGS.DEBUG_AD_TAG_URL + '&correlator=' + Date.now(); // A random positive number;
		}

		const isVposSet =
			this._adTagUrl &&
			this._adTagUrl.split('&vpos=') &&
			this._adTagUrl.split('&vpos=')[1] === typeAd;

		// If ad tag URL is available, return it directly
		if (isVposSet) return this._adTagUrl;

		const customParameters = await this.getAdTagCustomParameters();
		const nonPersonalizedAds = this._getNpaFromCmp();

		function buildAdTagUrl(
			iu: string,
			url: string,
			customParams: string,
			npa: string,
			vpos: string,
			adRule?: number
		) {
			let params: any = {
				iu,
				url: encodeURIComponent(url),
				description_url: encodeURIComponent(url),
				cust_params: encodeURIComponent(customParams),
				npa,
				correlator: Date.now(),
				vpos
			};

			if (adRule) {
				params = {
					...params,
					ad_rule: adRule
				};
			}

			return `${SETTINGS.DEFAULT_AD_TAG_URL}&${Object.keys(params)
				.map((k) => `${k}=${params[k]}`)
				.join('&')}`;
		}

		return buildAdTagUrl(
			this.adUnit,
			location.href,
			customParameters,
			nonPersonalizedAds,
			typeAd,
			this._allowMultipleTinyAds ? 1 : undefined
		);
	}

	protected logError(message: string) {
		this.publish(PublishableVideoLogEvents.ERROR, {
			message: message,
			category: this.name
		});
	}

	protected _playAdInstant(adTagUrl: string): boolean {
		if (!adTagUrl) return false;

		this._adTagUrl = adTagUrl;
		this._postPlayAdInstant();
		return true;
	}

	public publish(event: string, data: any = {}) {
		data.id = this._id; // To retrieve current instance
		PubSub.publish(event, data);

		const blacklistedFromConsoleLogs = ['video.ad.time_update'];
		if (blacklistedFromConsoleLogs.includes(event)) return;

		logger.debug(`Publishing ${event}`, data)();
	}

	public publishApiEvent(event: string, ad?: AdData) {
		const data = {
			ad: this._getAdData(ad), // Add extra data about ads
			videoBreak: this._getVideoBreak(ad), // Instream video ad break (preroll, postroll, ..)
			video: this._player ? this._player.videoId : undefined,
			url: document.URL
		};

		this.publish(event, data);
	}

	public onApiLoaded() {
		if (this._ready) return;

		this._initApiListeners();
		this._initErrorsListeners();

		if (this._player.muted && this.mute) {
			this.mute();
		}

		this.publishApiEvent(PublishableVideoAdEvents.AD_READY);
		this._ready = true;
	}

	public async getAdTagCustomParameters() {
		// Keywords targeting
		let customParameters = await this._getAdTargetingFormated(this.position);
		const playerWidth =
			this._player && this._player.ready
				? this._player.getWidth()
				: SETTINGS.DEFAULTplayer_WIDTH;
		const playerHeight =
			this._player && this._player.ready
				? this._player.getHeight()
				: SETTINGS.DEFAULTplayer_HEIGHT;

		customParameters +=
			'&playerwidth=' + playerWidth + '&playerheight=' + playerHeight;

		return customParameters;
	}

	public setHbResponses = (hbResponses) => (this._hbResponses = hbResponses);
	public isPlaying = () => this.adsState.playing;
	public isPaused = () => !this.adsState.playing;
	public isActive = () => this.adsState.started && !this.adsState.ended;

	private _logConsole = (logs: string, format?: string) =>
		Manager.get().logConsole(logs, format);

	private _userHasAdBlock = () =>
		window.afAdblock && window.afAdblock.status === 0;

	protected _getNpaFromCmp() {
		if (!window.Didomi) {
			return '0'; // NPA allowed by default if no CMP
		}

		// If website uses Didomi CMP, check for user content to non-personalized ads
		const userConsentToPersonalizedAds =
			window.Didomi.getUserConsentStatusForPurpose('cookies') &&
			window.Didomi.getUserConsentStatusForPurpose('select_basic_ads') &&
			window.Didomi.getUserConsentStatusForPurpose('create_ads_profile') &&
			window.Didomi.getUserConsentStatusForPurpose('select_personalized_ads') &&
			window.Didomi.getUserConsentStatusForPurpose('measure_ad_performance') &&
			window.Didomi.getUserConsentStatusForPurpose('market_research') &&
			window.Didomi.getUserConsentStatusForPurpose('improve_products');

		return userConsentToPersonalizedAds ? '0' : '1';
	}

	private _getPegasusBids(hbResponse: PegasusResponseBids): string {
		const amazon = hbResponse.amazon;
		const prebid = hbResponse.prebid;

		function getBids(
			item:
				| { [adUnitCode in AdUnitCode]: PegasusPreBidItem | PegasusAmazonItem }
				| undefined
		) {
			item = item ? item[Object.keys(item)[0]] : undefined;
			const prebidItem = item as unknown as PegasusPreBidItem;
			const amazonItem = item as unknown as PegasusAmazonItem;
			const isPrebid =
				prebidItem &&
				prebidItem.bids &&
				Array.isArray(prebidItem.bids) &&
				prebidItem.bids.length;
			const isAmazon =
				amazonItem && amazonItem.bids && amazonItem.bids?.targeting;

			return isPrebid
				? prebidItem.targeting
				: isAmazon
				? amazonItem.targeting
				: {};
		}

		const targetings = {
			...getBids(amazon),
			...getBids(prebid)
		};

		return Object.keys(targetings)
			.map((k) => `${k}=${targetings[k]}`)
			.join(';');
	}

	private async _getAdTargetingFormated(position: PlayerPosition) {
		logger.debug('_getAdTargetingFormated()')();

		let adTargeting = `video_pos=${
			position.toLowerCase().startsWith('mtf') ? 'mtf' : position.toLowerCase()
		};`;

		// Add GoFeminin targeting if needed
		adTargeting +=
			this.siteId === 4
				? getGoFemininTargeting(window.unify_dataSlayer, this._player.isMobile)
				: '';

		// Add pegasus hb responses
		const hasHbResponses = this._hasHbResponses(this._hbResponses);
		if (hasHbResponses) {
			const pegasusBids = this._getPegasusBids(this._hbResponses);

			if (pegasusBids)
				this.publishApiEvent(PublishableVideoAdEvents.AD_BID_FOUND);

			adTargeting += pegasusBids;
		}

		// Add debug targeting
		const debugPrerollAdTargeting = localStorage.getItem('video_force_preroll')
			? 'adtest=preroll'
			: '';
		adTargeting += `${window.sas_target};${this._adTargeting};${debugPrerollAdTargeting}`;

		// Add Pegasus Conf Keywords
		const pegasusConfKeywords = window.pegasusConf?.params?.keywords;
		const pegasusDotConfKeywords = window.pegasus?.conf?.params?.keywords;
		adTargeting += pegasusConfKeywords ? `;${pegasusConfKeywords}` : '';
		adTargeting += pegasusDotConfKeywords ? `;${pegasusDotConfKeywords}` : '';

		// Add Global targeting from Pegasus
		if (this.needPegasus) {
			logger.debug('_getAdTargetingFormated() : Need Pegasus')();
			const pegasusReady = async (): Promise<boolean> => {
				return new Promise((resolve, reject) => {
					let pegasusReady = false;

					const pegasusReadyInterval = window.setInterval(() => {
						const pegasus = window.pegasus;
						const googletag = window.googletag;
						if (pegasus.getGlobalTargeting && googletag.pubads) {
							pegasusReady = true;
							window.clearInterval(pegasusReadyInterval);
							return resolve(pegasusReady);
						}
					}, 150);

					window.setTimeout(() => {
						if (!pegasusReady) {
							const err = 'Pegasus timed out';
							logger.error(err + ', clearing interval')();
							window.clearInterval(pegasusReadyInterval);
							reject(err);
						}
					}, 10000);
				});
			};

			try {
				await pegasusReady();
			} catch (error) {
				return Promise.reject(error);
			}

			const globalTargeting = window.pegasus.getGlobalTargeting() || {};
			const pegasusTargetting = Object.keys(globalTargeting)
				.map(function (k) {
					return k + '=' + window.pegasus.getGlobalTargeting()[k].join(',');
				})
				.join(';');

			adTargeting += `;${pegasusTargetting}`;
		}

		function getGoFemininTargeting(
			datalayer: any[],
			isMobile?: boolean
		): string {
			datalayer = datalayer && Array.isArray(datalayer) ? datalayer : [];
			const pageItem = datalayer.find((elem) => elem.page);
			const lockVerz = pageItem
				? `lokverz1=${pageItem.page.section};lokverz2=${pageItem.page.subSection};`
				: '';

			return `pos=preroll;loktitel=gofeminin;${
				isMobile ? 'environment=mob;' : 'environment=sta;'
			}${lockVerz}`;
		}

		return Promise.resolve(AufVideoUtils.getDFPFormattedKeywords(adTargeting));
	}

	protected _getAdRule = () => (this._adRule ? 1 : 0);

	protected _setAdsState(state) {
		switch (state) {
			case PublishableVideoAdEvents.AD_START:
				this.adsState.started = true;
				this.adsState.ended = false;
				this.adsState.pods++;
				break;

			case PublishableVideoAdEvents.AD_END:
				this.adsState.started = false;
				this.adsState.playing = false;
				this.adsState.ended = true;
				break;

			case PublishableVideoAdEvents.AD_PAUSE:
				this.adsState.playing = false;
				this.adsState.paused = true;
				break;

			case PublishableVideoAdEvents.AD_PLAY:
			case PublishableVideoAdEvents.AD_RESUME:
				this.adsState.paused = false;
				this.adsState.started = true;
				this.adsState.playing = true;
				break;

			default:
				break;
		}

		this._pushToEventsList(state);
	}

	private _pushToEventsList(eventName) {
		this.adsState.eventsLists.push(this.name + ': ' + eventName);
	}

	private _hasHbResponses(hbResponses: PegasusResponseBids) {
		const prebid = hbResponses && hbResponses.prebid;
		const amazon = hbResponses && hbResponses.amazon;

		const hasPrebidProps = prebid
			? Object.getOwnPropertyNames(hbResponses.prebid).length
			: undefined;
		const hasAmazonProps = amazon
			? Object.getOwnPropertyNames(hbResponses.amazon).length
			: undefined;

		return hasPrebidProps || hasAmazonProps;
	}

	protected abstract _getAdData(ad?: any): any;
	protected abstract _getVideoBreak(ad: any): any;
	protected abstract _postInit(store?: Store, player?: any): Promise<any>;
	protected abstract _postPlayAdInstant(): any;
	protected abstract _initApiListeners(): void;
	protected abstract _initErrorsListeners(): void;
	public abstract mute(): void;
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.AdPlayer = AdPlayer;

import { Players } from 'src/enums/players.enum';
import {
	PublishableVideoContentEvents,
	PublishableVideoPlayerEvents
} from 'src/enums/publishable-events.enum';
import { DailymotionEmbedPlayer } from 'src/players/dailymotion-embed';
import { ListenerAction } from '../interfaces/listener-action';
import { Store, StoreInstance } from '../store';
import { trackAnalyticsEvent } from './utils/ga-tracking.util';
import PubSub from 'pubsub-js';

const EVENT_CATEGORY = 'Video';

interface ExtraDailymotionVideoData {
	type: string;
	datePublication: string;
}

export class GaVideoTracker {
	private _events: Map<string, Map<string, boolean>> = new Map<
		string,
		Map<string, boolean>
	>();

	private _store: Store;
	private static instance: GaVideoTracker;
	private _extraDailymotionData = new Map<string, ExtraDailymotionVideoData>();

	private _listenersActions: ListenerAction[] = [
		{
			message: PublishableVideoContentEvents.VIDEO_START,
			actionFn: async (msg, data) =>
				await this._trackEventOnceByVideo(data.id, 'Play', data)
		},
		{
			message: 'video.player.complete',
			actionFn: async (msg, data) =>
				await this._trackEventOnceByVideo(data.id, 'Completed', data)
		},
		{
			message: PublishableVideoPlayerEvents.UNMUTE,
			actionFn: async (msg, data) =>
				await this._trackEventOnceByVideo(data.id, 'player unmute', data)
		}
	];

	constructor() {
		this._init();
	}

	private _init() {
		this._store = (window as any).AufVideo.Store.get();
		this._initEventListeners(this._listenersActions);
	}

	private _initEventListeners(listenersActions: ListenerAction[]) {
		listenersActions.forEach((listenAction) =>
			PubSub.subscribe(listenAction.message, listenAction.actionFn)
		);
	}

	private async _trackEventOnceByVideo(
		instanceId: string,
		action: string,
		data: any
	) {
		const instance = this._store.getInstance(instanceId);
		const player = instance?.player;

		if (!player || !instance)
			throw new Error('Missing Store Instance or player');

		const videoId =
			player.name === Players.DAILYMOTION_EMBED
				? await (player as DailymotionEmbedPlayer).getVideo()
				: player.videoId;

		this._events[instanceId] = this._events[instanceId] || {};

		if (this._events[instanceId][action] === videoId) {
			return;
		}

		this._trackEvent(action, await this._getDataToSend(instance, data));
		this._events[instanceId][action] = videoId;
	}

	private _trackEvent = (action: string, dataToSend: any) =>
		trackAnalyticsEvent(
			EVENT_CATEGORY,
			action,
			dataToSend['video.title'],
			'',
			false,
			'',
			100,
			dataToSend
		);

	private async _getDataToSend(instance: StoreInstance, customData: any) {
		const player = instance.player;
		const videoFileUrl =
			(customData && customData.file) ||
			((player as any).getFile && (player as any).getFile.apply(player)) ||
			'';

		let extraDailymotionData = this._extraDailymotionData.get(player.videoId);
		const shouldFetch =
			!extraDailymotionData &&
			player.name === Players.DAILYMOTION_EMBED &&
			player.videoId;
		if (shouldFetch) {
			const getExtraAnalyticsData = async (
				dailymotionId: string
			): Promise<ExtraDailymotionVideoData> => {
				try {
					console.log('window.location', window.location);

					const routePath = `reloaded/api/videos/extraanalyticsdata/${dailymotionId}`;
					const internalDomains =
						/alfemminile|aufeminin|marmiton|enfeminino|taofeminino|gofeminin|wewomen|sofeminin|1001cocktails|petitsplatsenequilibre/;
					const routeUrl = window.location.origin.match(internalDomains)
						? `/${routePath}`
						: `https://www.aufeminin.com/${routePath}`;

					const response = await fetch(routeUrl);
					const json = await response.json();

					return Promise.resolve({
						type: json.videoType,
						datePublication: json.datePublication
					});
				} catch (error) {
					const errMsg = 'Cannot retrieve extra Analytics data';

					console.error(errMsg, error);

					// Return an object with unknown to let processing continue
					return Promise.resolve({
						type: 'unknown',
						datePublication: 'unknown'
					});
				}
			};

			extraDailymotionData = await getExtraAnalyticsData(player.videoId);
			this._extraDailymotionData.set(player.videoId, extraDailymotionData);
		}

		return {
			'video.id': player.videoId,
			'video.context': instance.context,
			'video.place': instance.position,
			'video.url': videoFileUrl,
			'video.tags': '', // Not available
			'video.title': player.videoTitle,
			'video.player': player.name,
			'video.position': player.videosPlayed.length,
			'video.type': extraDailymotionData?.type,
			'video.date_publication': extraDailymotionData?.datePublication
		};
	}

	public static get(): GaVideoTracker {
		if (!GaVideoTracker.instance) {
			GaVideoTracker.instance = new GaVideoTracker();
		}

		return GaVideoTracker.instance;
	}
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.Tracker = window.AufVideo.Tracker || {};
window.AufVideo.Tracker.GA = GaVideoTracker;

const disabledStickySessionVarName = 'stickySessionDisabled';

const isStickySessionDisabled = () =>
	typeof sessionStorage.getItem(disabledStickySessionVarName) !== 'undefined' &&
	Boolean(sessionStorage.getItem(disabledStickySessionVarName));

const setDisabledStickyForSessionState = () =>
	sessionStorage.setItem(disabledStickySessionVarName, 'true');

export { isStickySessionDisabled, setDisabledStickyForSessionState };

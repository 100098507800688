export class AufVideoUtils {
	public static addClickListener(
		containerId: string,
		classNames: string[],
		callback: (...args: any[]) => any,
		options?: any
	) {
		classNames.forEach(function (className) {
			const elements = document
				.getElementById(containerId)!
				.getElementsByClassName(className);

			elements[0].addEventListener('click', function (e) {
				callback();
				if (options && options.stopPropagation) {
					e.stopPropagation();
				}
			});
		});
	}

	public static getCookieValue(cname: string): string {
		const name = cname + '=';
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	}

	public static getDFPFormattedKeywords(keywords: string) {
		let formattedKeywords = '';

		// First we clean keywords to be sure to respect DFP syntax
		const cleanedKeywords = keywords
			.replace(/^\W/, '') // Remove non-word character
			.replace(/;/g, '&') // Replace Smart keywords separator ; by &
			.replace(/&+/g, '&'); // Replace multiple &

		cleanedKeywords.split('&').forEach(function (keyValue) {
			const keyValueArr = keyValue.split('=');
			const key = keyValueArr[0];
			const value = keyValueArr[1];

			if (!key || typeof value === 'undefined') {
				return;
			}

			const keyQS = '&' + key + '=';
			const keyQSPos = formattedKeywords.indexOf(keyQS);

			// Key already exists
			if (keyQSPos !== -1) {
				let keyValues = formattedKeywords.substr(keyQSPos + keyQS.length);

				// Extract only values
				if (keyValues.indexOf('&') !== -1) {
					keyValues = keyValues.substr(0, keyValues.indexOf('&'));
				}

				// Dedupe same values
				if (keyValues.indexOf(value) !== -1) {
					return;
				}

				// Add new value for the key
				formattedKeywords =
					formattedKeywords.substr(0, keyQSPos + keyQS.length) +
					value +
					',' +
					formattedKeywords.substr(keyQSPos + keyQS.length);
			} else {
				formattedKeywords += keyQS + value;
			}
		});

		// Remove first separator
		if (formattedKeywords.indexOf('&') === 0) {
			formattedKeywords = formattedKeywords.substr(1);
		}

		return formattedKeywords;
	}

	public static throttle(
		func: (...args: any[]) => any,
		wait: number,
		options: { leading?: boolean; trailing?: boolean }
	) {
		let context, args, result;
		let timeout: number | null = null;
		let previous = 0;
		if (!options) options = {};
		let later = function () {
			previous = options.leading === false ? 0 : Date.now();
			timeout = null;
			result = func.apply(context, args);
			if (!timeout) context = args = null;
		};
		return function () {
			let now = Date.now();
			if (!previous && options.leading === false) previous = now;
			let remaining = wait - (now - previous);
			context = this;
			args = arguments;
			if (remaining <= 0 || remaining > wait) {
				if (timeout) {
					clearTimeout(timeout);
					timeout = null;
				}
				previous = now;
				result = func.apply(context, args);
				if (!timeout) context = args = null;
			} else if (!timeout && options.trailing !== false) {
				timeout = window.setTimeout(later, remaining);
			}
			return result;
		};
	}

	public static isPreview() {
		const search = window.location.search;
		const params = search.substring(search.lastIndexOf('?') + 1).split('&');

		return params.includes('preview');
	}

	public static injectScript(
		d: Document,
		src: string,
		cb: Function,
		params?: any[]
	) {
		var script = d.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.onload = function () {
			// remote script has loaded
			if (cb && typeof cb === 'function') {
				cb(params);
			}
		};
		script.src = src;
		d.getElementsByTagName('head')[0].appendChild(script);
	}
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.Utils = AufVideoUtils;

import { setChildren, el } from 'redom';
import { merge, Observable, Subject } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { StickyButtonsEvents } from 'src/plugins/sticky/enums/buttons-events.enum';
import { Visibility } from 'src/plugins/sticky/enums/visibility.enum';
import { StickyButtonVisibilityEvent } from 'src/plugins/sticky/interfaces/sticky-button-visibility-event.interface';
import { getClassname } from 'src/plugins/sticky/utils/get-classname.util';
import { StickyBaseElement } from '../../base-element';
import { StickyButton } from '../../global/buttons/button.component';
import { StickyButtonsBarOptions } from './buttons-bar.interface';

export class StickyButtonsBar extends StickyBaseElement {
	goBackButton: StickyButton;
	closeButton: StickyButton;
	events$: Observable<StickyButtonsEvents>;

	switchVisibility$ = new Subject<StickyButtonVisibilityEvent>();

	constructor(settings: StickyButtonsBarOptions) {
		const { customClass } = settings;
		super(el('buttons-bar'), getClassname(['sticky-buttons-bar'], customClass));

		this.goBackButton = new StickyButton({
			icon: 'icon-arrow-up',
			customClass: ['af-btn-v2', 'af-btn-v2-center', 'gotoplayer']
		});
		this.closeButton = new StickyButton({
			customClass: 'close'
		});

		this.events$ = merge(
			this.goBackButton.clicked$.pipe(mapTo(StickyButtonsEvents.GO_TO_PLAYER)),
			this.closeButton.clicked$.pipe(mapTo(StickyButtonsEvents.CLOSE))
		);

		this.switchVisibility$.subscribe((event: StickyButtonVisibilityEvent) => {
			const doSwitchVisibilty = (target: StickyButton, state: Visibility) => {
				if (state === Visibility.HIDDEN) {
					target.el.classList.add('hidden');
				} else if (state === Visibility.VISIBLE) {
					target.el.classList.remove('hidden');
				}
			}

			switch (event.btn) {
				case StickyButtonsEvents.CLOSE: {
					doSwitchVisibilty(this.closeButton, event.state);
				} break;

				case StickyButtonsEvents.GO_TO_PLAYER: {
					doSwitchVisibilty(this.goBackButton, event.state);
				} break;

				default: {
					console.error('This button visibility switch is not yet implemented');
				} break;
			}
		});

		setChildren(this.el, [this.goBackButton, this.closeButton]);
	}
}

import { setChildren, mount, el } from 'redom';
import { getClassname } from 'src/plugins/sticky/utils/get-classname.util';
import { StickyPlaceholder } from '../../global/placeholder/placeholder.component';
import { StickyButtonsBar } from '../../global/buttons-bar/buttons-bar.component';
import { StickyBaseLayout } from '../base-layout';
import { from, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { BannerLayoutSettings } from './banner-layout.interface';
import { StickyBaseElement } from '../../base-element';
import { Visibility } from 'src/plugins/sticky/enums/visibility.enum';
import { StickyButtonsEvents } from 'src/plugins/sticky/enums/buttons-events.enum';

export interface StickyBannerVideoInfo {
	title: string;
	duration?: string;
}

export class BannerStickyLayout extends StickyBaseLayout {
	background: StickyPlaceholder;
	buttonsBar: StickyButtonsBar;
	contentWrapper: StickyBaseElement;
	videoInfosElement: StickyBaseElement;
	stickyBannerVideosInfos$ = new Subject<StickyBannerVideoInfo>();

	private _subscriptions: Subscription[] = [];
	private _videoTitleElement: StickyBaseElement;
	private _videoDurationElement: StickyBaseElement;

	constructor(settings: BannerLayoutSettings) {
		let { customClass, displayVideosInfos, position = 'bottom' } = settings;
		customClass = customClass ?? [];

		const customClassList =
			typeof customClass === 'string' ? [customClass] : customClass;

		super({
			...settings,
			customClass: getClassname(['sticky-banner-layout'], customClassList)
		});

		this.background = new StickyPlaceholder({
			customClass: 'banner-layout-background'
		});

		this.buttonsBar = new StickyButtonsBar({
			customClass: 'banner-layout-buttons'
		});

		this.contentWrapper = new StickyBaseElement(
			el('sticky-content-wrapper'),
			'sticky-content-wrapper'
		);

		this.videoInfosElement = new StickyBaseElement(
			el('sticky-video-infos'),
			'sticky-video-infos'
		);

		this._videoTitleElement = new StickyBaseElement(el('p'), 'video-title');
		this._videoDurationElement = new StickyBaseElement(
			el('p'),
			'video-duration'
		);
		setChildren(this.videoInfosElement, [
			this._videoTitleElement,
			this._videoDurationElement
		]);

		setChildren(this.contentWrapper, [
			this.playerElement,
			this.videoInfosElement
		]);
		setChildren(this.playerWrapper, [this.buttonsBar, this.contentWrapper]);
		setChildren(this.el, [this.background, this.playerWrapper]);

		this.toggleableElements = [
			this.background,
			this.buttonsBar,
			this.videoInfosElement
		];

		this.events$ = from(this.buttonsBar.events$);

		this.setPosition(position);
		this.toggleVideosInfo(displayVideosInfos ? true : false);
		this.sticked$.next(false);

		this.closeBtnVisibility$.pipe(distinctUntilChanged()).subscribe((state: Visibility) => {
			this.buttonsBar.switchVisibility$.next({
				btn: StickyButtonsEvents.CLOSE,
				state
			})
		});

		this.mount();
	}

	onmount() {
		const subscription = this.stickyBannerVideosInfos$.subscribe(
			(videoInfo) => {
				this._videoTitleElement.el.textContent = videoInfo.title;
				this._videoDurationElement.el.textContent = '' + videoInfo.duration;
			}
		);

		this._subscriptions.push(subscription);
	}

	onunmount() {
		this._subscriptions.forEach((sub) => sub.unsubscribe());
	}

	toggleVideosInfo(force: boolean) {
		this.el.classList.toggle('video-infos-displayed', force);
	}

	setPosition(position: 'top' | 'bottom') {
		const addedClass = position === 'top' ? 'position-top' : 'position-bottom';
		const removedClass =
			position === 'top' ? 'position-bottom' : 'position-top';

		this.el.classList.remove(removedClass);
		this.el.classList.add(addedClass);
	}

	mount = () => mount(this.parentNode, this);
}

declare global {
	interface Window {
		m_dataLayer: any;
		af_dataLayer: any;
		unify_dataSlayer: any;
		_gaq: any;
		gdprAppliesGlobally: boolean;
		didomiOnReady: Function[];
	}
}

interface GaEvent {
	event: 'UA - Event' | 'uaevent';
	eventCategory: string;
	eventAction: string;
	eventLabel?: string;
	eventValue?: any;
	eventNonInt: boolean;
	data: any;
}

interface MobileGAEvent {
	event: 'UA-Event';
	uaEventCategory: string;
	uaEventAction: string;
	uaEventLibelle: string;
	uaEventValue: any;
	uaEventNonInt: boolean;
	data: any;
}

export const trackAnalyticsEvent = (
	category: string,
	action: string,
	opt_label = '',
	opt_value: any,
	opt_noninteraction = false,
	prefix = '',
	sampling = 100,
	customData?: any
) => {
	// Block useless events to avoid reaching GA limitations
	if (category === 'AF_Album' && action === 'go') return;
	if (category === 'AF_Album' && action === 'prev') return;
	if (category === 'AF_Album' && action === 'next') return;

	// Sample events
	if (
		!isNaN(parseInt('' + sampling)) &&
		Math.floor(Math.random() * 100) > +sampling
	) {
		return;
	}

	const eventBase = {
		eventCategory: category,
		eventAction: action,
		eventLabel: opt_label,
		eventValue: opt_value,
		eventNonInt: true
	};

	let event: GaEvent | MobileGAEvent;

	if (typeof window.m_dataLayer !== 'undefined') {
		event = {
			event: 'UA-Event',
			uaEventCategory: category,
			uaEventAction: action,
			uaEventLibelle: opt_label,
			uaEventValue: opt_value,
			uaEventNonInt: true,
			...customData
		};

		window.m_dataLayer.push(event);
	} else if (typeof window.af_dataLayer !== 'undefined') {
		event = {
			event: 'UA - Event',
			...eventBase,
			...customData
		};

		window.af_dataLayer.push(event);
	} else if (typeof window._gaq !== 'undefined') {
		if (prefix != '' && prefix.substr(-1) != '.') prefix += '.';
		window._gaq.push([
			prefix + '_trackEvent',
			category,
			action,
			opt_label,
			opt_value,
			opt_noninteraction
		]);
	}

	if (typeof window.unify_dataSlayer !== 'undefined') {
		event = {
			event: 'uaevent',
			...eventBase,
			...customData
		};

		if (window.gdprAppliesGlobally) {
			// Site with GDPR: wait for CMP consent before adding any GA event to the datalayer, otherwise these events are not tracked properly
			window.didomiOnReady = window.didomiOnReady || [];
			window.didomiOnReady.push(function () {
				window.unify_dataSlayer.push(event);
			});
		} else {
			// Site without GDPR: fire events synchronously
			window.unify_dataSlayer.push(event);
		}
	}
};

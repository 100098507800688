export const applyCssTranslate = (elt: HTMLElement, x: number, y: number) => {
	window.requestAnimationFrame(() =>
		'transform webkitTransform mozTransform oTransform msTransform'
			.split(' ')
			.forEach(
				(prefix) =>
					(elt.style[prefix] = `translate(' + ${x} + ', ' + ${y} + ')`)
			)
	);
};

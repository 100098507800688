/*
 * This file's responsability is to provide a permutive implementation for Dailymotion Embed
 * https://permutive.notion.site/DailyMotion-Integration-v2-8e19f3c592f34146a6e4dc6f872c7633
 * */

import { DailymotionEmbedApi } from 'src/interfaces/dailymotion-embed.interface';
import { VideoConsoleLogger } from './video-logger';

const logger = new VideoConsoleLogger();

export class PermutiveService {
	private _permutive: any;
	private _dailymotion: any;
	private _percentagePlayedMarkers = [25, 50, 75, 100];
	private _lastKnownPlayedPositionPercent: number;
	private _eventsToListen = [
		'PLAYER_START',
		'PLAYER_VOLUMECHANGE',
		'PLAYER_PRESENTATIONMODECHANGE',
		'VIDEO_START',
		'VIDEO_PLAY',
		'VIDEO_PAUSE',
		'AD_START',
		'AD_PLAY',
		'AD_PAUSE',
		'AD_CLICK'
	];
	private _allowedPermutiveEventProperties = [
		'adAdvertiserName',
		'adDuration',
		'adEndedReason',
		'adId',
		'adIsSkippable',
		'adPosition',
		'adTitle',
		'playerIsMuted',
		'playerIsViewable',
		'videoDuration',
		'videoId',
		'videoTitle'
	];

	constructor(permutive: any, dailymotion: any) {
		this._permutive = permutive || window.permutive;
		this._dailymotion = dailymotion;
	}

	public attachPermutiveGoogleAdManagerTargeting() {
		this._checkPermutive();

		return encodeURIComponent(
			'&permutive=' +
				JSON.parse(window.localStorage._pdfps || '[]')
					.slice(0, 250)
					.toString() +
				'&puid=' +
				window.localStorage.getItem('permutive-id') +
				'&ptime=' +
				Date.now().toString() +
				'&prmtvvid=' +
				this._permutive.config.viewId +
				'&prmtvwid=' +
				this._permutive.config.workspaceId
		);
	}

	public attachPermutiveEventsToDailyEmbed(player: DailymotionEmbedApi) {
		this._checkPermutive();

		['VIDEO_TIMECHANGE', 'VIDEO_SEEKSTART', 'AD_TIMECHANGE'].forEach(
			(eventName) => {
				player.on(this._dailymotion.events[eventName], (state) => {
					this._videoPercentagePlayed(eventName, state);
				});
			}
		);

		this._eventsToListen.forEach((eventName) => {
			player.on(this._dailymotion.events[eventName], (state: any) => {
				state.eventName = eventName;
				const permutiveEventContent = {
					eventName: eventName
				};

				this._allowedPermutiveEventProperties.forEach((property) => {
					if (!state.hasOwnProperty(property) || !state[property]) {
						return;
					}
					if (property === 'videoCreatedTime') {
						permutiveEventContent[property] = new Date(
							state.videoCreatedTime
						).toISOString();
						return;
					}
					permutiveEventContent[property] = state[property];
				});
				this._permutive.track('DailymotionState', permutiveEventContent);
			});
		});
	}

	private _videoPercentagePlayed = (eventName: string, state: any) => {
		let playedPositionPercent = Math.floor(
			(state.videoTime * 100) / state.videoDuration
		);
		const lastKnownPlayedPositionPercent = this._lastKnownPlayedPositionPercent;
		this._lastKnownPlayedPositionPercent = playedPositionPercent;

		if (lastKnownPlayedPositionPercent === playedPositionPercent) return;

		this._percentagePlayedMarkers.some((played_depth) => {
			if (
				(playedPositionPercent >= played_depth &&
					this._lastKnownPlayedPositionPercent < played_depth) ||
				(playedPositionPercent <= played_depth &&
					lastKnownPlayedPositionPercent > played_depth)
			) {
				const permutiveEventContent = {
					eventName: eventName,
					played: played_depth / 100
				};
				this._allowedPermutiveEventProperties.forEach(function (property) {
					if (!state.hasOwnProperty(property) || !state[property]) {
						return;
					}
					permutiveEventContent[property] = state[property];
				});
				this._permutive.track('DailymotionState', permutiveEventContent);
				return true;
			}
			return false;
		});
	};

	private _checkPermutive() {
		// As Permutive is loaded appart of the bundle, there is a race condition then reinit if needed;
		this._permutive = this._permutive || window.permutive;

		if (!this._permutive) {
			const errMsg = 'Cannot attach permutive targeting: permutive not found';
			logger.error(errMsg)();
			throw new Error(errMsg);
		}
	}
}

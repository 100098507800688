import { el, mount } from 'redom';
import { getClassname } from 'src/plugins/sticky/utils/get-classname.util';
import { StickyBaseElement } from '../../base-element';
import { StickyPlaceholderOptions } from './placeholder.interface';

const DEFAULT_CLASSNAME = ['video-player-sticky-bg'];

export class StickyPlaceholder extends StickyBaseElement {
	el: HTMLDivElement;

	constructor(settings: StickyPlaceholderOptions = {}) {
		const { customClass } = settings;

		super(el('div'), getClassname(DEFAULT_CLASSNAME, customClass));
	}

	mount = (node: ParentNode) => mount(node, this);
}

// User-agent module
// TODO: add OS detection
export const afUserAgent = (function () {
	var _isMobile = false;
	var _isTablet = false;
	var _regexMobile =
		/(ipod|blackberry|phone|mobile|opera mini|pda|symbian|3ds|nokia|ericsson|alcatel)/gi;
	var _regexTabletSmall =
		/(pad|lenovo|tab|GT-P5210|GT-P5110|Nexus 7|GT-N8010|GT-P3110)/gi;
	var _regexTablet = /(pad|tab|android)/gi;

	var _initialized = false;
	function _init() {
		if (_initialized) return;

		_initialized = true;
		if (navigator.userAgent.match(_regexMobile)) {
			if (navigator.userAgent.match(_regexTabletSmall)) {
				_isTablet = true;
			} else {
				_isMobile = true;
			}
		} else if (
			navigator.userAgent.match(_regexTablet) &&
			!navigator.userAgent.match(/(pc)/gi)
		) {
			// TEMP: quick fix for IE11 detected as tablet
			_isTablet = true;
		}
	}

	function isMobile() {
		_init();
		return _isMobile;
	}

	function isTablet() {
		_init();
		return _isTablet;
	}

	return {
		isMobile: isMobile,
		isTablet: isTablet
	};
})();

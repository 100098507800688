export type LogLevel = 'info' | 'debug' | 'warn' | 'error';

export class VideoConsoleLogger {
	constructor() {}

	/**
	 * Send colorized info console log
	 *
	 * Activate hidden logs this way: localStorage.setItem('log', JSON.stringify({videoPlayer: true}));
	 *
	 * @param msg {string} Message to be sent
	 * @param hidden {boolean} - If true the message will be sent only if localStorage.logs.videoplayer is true
	 * @param data {object} - Optional data to log
	 */
	public info = (msg: string, data?: any, hidden: boolean = true) =>
		this._writeLog(msg, 'info', hidden, data);

	/**
	 * Send colorized warning console log
	 *
	 * Activate hidden logs this way: localStorage.setItem('log', JSON.stringify({videoPlayer: true}));
	 *
	 * @param msg {string} Message to be sent
	 * @param hidden {boolean} - If true the message will be sent only if localStorage.logs.videoplayer is true
	 * @param data {object} - Optional data to log
	 */
	public warn = (msg: string, data?: any, hidden: boolean = true) =>
		this._writeLog(msg, 'warn', hidden, data);

	/**
	 * Send colorized debug console log
	 *
	 * Activate hidden logs this way: localStorage.setItem('log', JSON.stringify({videoPlayer: true}));
	 *
	 * @param msg {string} Message to be sent
	 * @param hidden {boolean} - If true the message will be sent only if localStorage.logs.videoplayer is true
	 * @param data {object} - Optional data to log
	 */
	public debug = (msg: string, data?: any, hidden: boolean = true) =>
		this._writeLog(msg, 'debug', hidden, data);

	/**
	 * Send colorized error console log
	 *
	 * Activate hidden logs this way: localStorage.setItem('log', JSON.stringify({videoPlayer: true}));
	 *
	 * @param msg {string} Message to be sent
	 * @param hidden {boolean} - If true the message will be sent only if localStorage.logs.videoplayer is true
	 * @param data {object} - Optional data to log
	 */
	public error = (msg: string, data?: any, hidden: boolean = true) =>
		this._writeLog(msg, 'error', hidden, data);

	/**
	 * Send colorized console logs
	 *
	 * Activate hidden logs this way: localStorage.setItem('log', JSON.stringify({videoPlayer: true}));
	 *
	 * @param msg {string} Message to be sent
	 * @param level {LogLevel} Log level
	 * @param hidden {boolean} - If true the message will be sent only if localStorage.logs.videoplayer is true
	 * @param data {object} - Optional data to log
	 */
	private _writeLog(
		msg: string,
		level: LogLevel = 'info',
		hidden: boolean = true,
		data?: any
	): Function {
		if (
			(hidden && !localStorage.log) ||
			!JSON.parse(localStorage.log).videoPlayer
		) {
			return () => {};
		}

		const getColorString = (level: LogLevel): string => {
			let frontColor: string;
			let backgroundColor: string;

			switch (level) {
				case 'info':
					{
						frontColor = 'yellow';
						backgroundColor = 'Purple';
					}
					break;

				case 'debug':
					{
						frontColor = 'orange';
						backgroundColor = 'Purple';
					}
					break;

				case 'error':
					{
						frontColor = 'red';
						backgroundColor = 'Purple';
					}
					break;

				default:
					{
						frontColor = 'white';
						backgroundColor = 'Purple';
					}
					break;
			}

			return `color: ${frontColor}; background: ${backgroundColor};`;
		};

		const color = getColorString(level);
		msg = `%c( Video Player - ${level} )%c ${msg}`;

		const fn =
			level === 'error'
				? console.error.bind(window.console, msg, color, data)
				: console.log.bind(window.console, msg, color, data);

		return fn;
	}
}

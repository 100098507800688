import {
	PublishableVideoAdEvents,
	PublishableVideoContentEvents,
	PublishableVideoLogEvents,
	PublishableVideoPlayerEvents,
	PublishableVideoStickyEvents
} from '../../enums/publishable-events.enum';
import { ListenerAction } from '../../interfaces/listener-action';
import { Store } from '../../store';
import PubSub from 'pubsub-js';
import { Players } from 'src/enums';
import { DailymotionEmbedPlayer } from 'src/players/dailymotion-embed';

export interface AnalyticsPluginOptions {
	store: Store;
}

export class AnalyticsPlugin {
	public name = 'analytics';
	private _store: Store;
	private _events: Map<string, Map<string, boolean>> = new Map<
		string,
		Map<string, boolean>
	>();

	private _playerListenersActions: ListenerAction[] = [
		{
			message: PublishableVideoLogEvents.ERROR,
			actionFn: (msg, data) =>
				this._logError(data.message, data.category, data.id)
		},
		{
			message: 'video.player.log.event',
			actionFn: (msg, data) => {
				var event = data.event;
				delete data.event;
				this._logEventOnce(event, data);
			}
		},
		{
			message: PublishableVideoPlayerEvents.READY,
			actionFn: (msg, data) => this._logEventOnce('player ready', data)
		},
		{
			message: PublishableVideoPlayerEvents.START,
			actionFn: (msg, data) => {
				this._logEventOnce('player start', data);
				this._logEventOnce('ad opportunities', data); // TODO: remove when NR video dashboard has been migrated to the other event
			}
		},
		{
			message: PublishableVideoPlayerEvents.PLAY,
			actionFn: (msg, data) => this._logEvent('player play', data)
		},
		{
			message: PublishableVideoContentEvents.VIDEO_END,
			actionFn: (msg, data) => this._logEventOnce('player complete', data)
		},
		{
			message: 'video.player.revolver',
			actionFn: (msg, data) => this._logEventOnce('player revolver', data)
		},
		{
			message: PublishableVideoStickyEvents.CLOSE,
			actionFn: (msg, data) => this._logEventOnce('sticky player closed', data)
		},
		{
			message: PublishableVideoPlayerEvents.UNMUTE,
			actionFn: (msg, data) => this._logEvent('player unmute', data)
		},
		{
			message: PublishableVideoPlayerEvents.INITIALIZATION_ERROR,
			actionFn: (msg, data) =>
				this._logEvent('player initialization error', data)
		}
	];

	private _adPlayerListenersActions: ListenerAction[] = [
		{
			message: PublishableVideoAdEvents.AD_BLOCKED,
			actionFn: (msg, data) => this._logEventOnce('adblock detected', data)
		},
		{
			message: PublishableVideoAdEvents.AD_READY,
			actionFn: (msg, data) => this._logEvent('ad player ready', data)
		},
		{
			message: PublishableVideoAdEvents.AD_LOADED,
			actionFn: (msg, data) =>
				this._logEvent('ad ' + data.videoBreak + ' ready', data)
		},
		{
			message: PublishableVideoAdEvents.AD_UNFILLED,
			actionFn: (msg, data) => this._logEvent('ad unfilled', data)
		},
		{
			message: PublishableVideoAdEvents.AD_PLAY,
			actionFn: (msg, data) =>
				this._logEventOnceByVideo(
					data.id,
					'ad ' + data.videoBreak + ' play',
					data
				)
		},
		{
			message: PublishableVideoAdEvents.AD_START,
			actionFn: (msg, data) =>
				this._logEventOnce('ad ' + data.videoBreak + ' start', data)
		},
		{
			message: PublishableVideoAdEvents.AD_REQUEST,
			actionFn: (msg, data) =>
				this._logEventOnce('ad ' + data.videoBreak + ' call', data)
		},
		{
			message: PublishableVideoAdEvents.AD_END,
			actionFn: (msg, data) =>
				this._logEvent('ad ' + data.videoBreak + ' end', data)
		},
		{
			message: PublishableVideoAdEvents.AD_CLICK,
			actionFn: (msg, data) =>
				this._logEvent('ad ' + data.videoBreak + ' click', data)
		},
		{
			message: PublishableVideoAdEvents.AD_IMPRESSION,
			actionFn: (msg, data) =>
				this._logEvent('ad ' + data.videoBreak + ' impression', data)
		},
		{
			message: PublishableVideoAdEvents.AD_CONTENT_RESUME,
			actionFn: (msg, data) => this._logEvent('ad content resume', data)
		},
		{
			message: PublishableVideoAdEvents.AD_BID_FOUND,
			actionFn: (msg, data) => this._logEvent('ad bid found', data)
		},
		{
			message: PublishableVideoAdEvents.HEAVY_ADS,
			actionFn: (msg, data) => this._logEvent('heavy ad error', data)
		}
	];

	constructor(options: AnalyticsPluginOptions) {
		this._store = options.store;
		this.init();
	}

	public init() {
		this._initListeners([
			...this._playerListenersActions,
			...this._adPlayerListenersActions
		]);
	}

	private _getLogger = (instanceId: string) => {
		try {
			const storeInstance = this._store.getInstance(instanceId);
			return storeInstance?.logger;
		} catch (error) {
			console.error(error);
			console.error(instanceId + ' store instance not found');
		}
	};

	private _logError = (message: string, category: string, instanceId: string) =>
		this._getLogger(instanceId).logError(message, category);

	private _logEvent = (message, data) =>
		this._getLogger(data.id).logEvent(message, data);

	private _logEventOnce = (message, data) =>
		this._getLogger(data.id).logEventOnce(message, data);

	private async _logEventOnceByVideo(
		instanceId: string,
		action: string,
		data: any
	) {
		const instance = this._store.getInstance(instanceId);
		const player = instance?.player;

		if (!player || !instance)
			throw new Error('Missing Store Instance or player');

		const videoId =
			player.name === Players.DAILYMOTION_EMBED
				? await (player as DailymotionEmbedPlayer).getVideo()
				: player.videoId;

		this._events[instanceId] = this._events[instanceId] || {};

		if (this._events[instanceId][action] === videoId) {
			return;
		}

		this._logEvent(action, data);
		this._events[instanceId][action] = videoId;
	}

	private _initListeners = (listenersActions: ListenerAction[]) =>
		listenersActions.forEach((listenAction) =>
			PubSub.subscribe(listenAction.message, listenAction.actionFn)
		);
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.Analytics = AnalyticsPlugin;

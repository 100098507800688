/**
 * This file's responsability is to initialize objects previously created by the spawner
 */

import DailymotionEmbedAdPlayer from './adPlayers/dailymotion-embed';
import { PublishableVideoStickyEvents } from './enums/publishable-events.enum';
import { VideoPlugins } from './enums/video-plugins.enum';
import { AdsParams } from './interfaces';
import { DailymotionEmbedPlayer } from './players/dailymotion-embed';
import { AnalyticsPlugin } from './plugins/analytics/analytics';
import { AutoplayPlugin } from './plugins/autoplay/autoplay';
import { DebugPlugin } from './plugins/debug/debug';
import { StickyPlugin } from './plugins/sticky/sticky-core';

import { Store, StoreInstance } from './store';
import { PlayerInstance } from './types/player-instance.type';
import { AufVideoUtils } from './utils/utils';
import { VideoConsoleLogger } from './utils/video-logger';
import AfLogger from '@vendor/auf/afLogger';
import PubSub from 'pubsub-js';
import { PegasusResponseBids } from './interfaces/pegasus-response-bids.interface';

const logger = new VideoConsoleLogger();
const afLogger = new AfLogger({ disableSessionData: true });

export interface ManagerOptions {
	labels?: any;
}

const PEGASUS_TIMEOUT = 7000;

export class Manager {
	private labels;
	private store: Store;
	private storeInstance: StoreInstance;
	public plugins: Map<string, any> = new Map<string, any>();
	private static instance: Manager;

	constructor(options: ManagerOptions = {}) {
		this.labels = options.labels;
		this._init();
	}

	// TODO @4lador: Check if that still deserve a purpose
	public logConsole = (logs: string, service?: string) =>
		afLogger.logConsole(logs, 'log', service);

	private _init(): void {
		// Mandatory initializations
		this.store = Store.get();

		this._initListeners();

		const plugins = [
			VideoPlugins.ANALYTICS,
			VideoPlugins.STICKY,
			VideoPlugins.AUTOPLAY
		];

		const isLocal = location.host.indexOf('local') === 0;

		if (isLocal) {
			plugins.push(VideoPlugins.DEBUG);
		}

		this._initPlugins(plugins);
		this._initScrollListener();
	}

	private _initListeners(): void {
		const that = this;

		// Init instance once it has been registered to the store
		PubSub.subscribe(
			'video.store.addInstance',
			async (msg, data) => {
				logger.debug(`AddInstance msg received from ${data?.instance?.player?.id}`)();
				await this._initInstance(data.instance);
			}
		);

		initAutopause();

		function initAutopause() {
			// Automatically pause the previous playing instance when a player starts / resumes
			PubSub.subscribe('video.store.playingInstance.update', (msg, data) =>
				that._pausePreviousPlayingInstance(data.id)
			);
			PubSub.subscribe('video.ad.start', (msg, data) =>
				that._pausePreviousPlayingInstance(data.id)
			);
			PubSub.subscribe('video.ad.resume', (msg, data) =>
				that._pausePreviousPlayingInstance(data.id)
			);
			PubSub.subscribe('video.player.start', (msg, data) =>
				that._pausePreviousPlayingInstance(data.id)
			);
		}
	}

	private async _initInstance(instance: StoreInstance): Promise<void> {
		this.storeInstance = instance;

		if (!(window as any).gdprAppliesGlobally) {
			await this._initPlayers(instance);
		}

		// TODO: Type Didomi
		const didomiOnReady = ((window as any).didomiOnReady =
			(window as any).didomiOnReady || []);

		didomiOnReady.push(async () => {
			const didomi = (window as any).Didomi;
			const consentShouldBeCollected = didomi.shouldConsentBeCollected();
			const didomiEventListeners: { event: string; listener: Function }[] = (
				window as any
			).didomiEventListeners;

			if (consentShouldBeCollected) {
				// If CMP consent notice popup is shown, defer players init until popup has been closed
				didomiEventListeners.push({
					event: 'notice.hidden',
					listener: async () => await this._initPlayers(instance)
				});

				logger.debug(`Init player ${instance.player.id} pushed to Didomi listener`)();
				return;
			}

			// Otherwise init players directly because it means user has already given his consent
			await this._initPlayers(instance);
		});
	}

	private async _initPlayers(
		instance: StoreInstance = this.storeInstance
	): Promise<void> {
		const player = instance.player;
		const adPlayer = instance.adPlayer;
		const npaActivated = adPlayer && adPlayer._getNpaFromCmp() === '0';
		const usePegasus = adPlayer && npaActivated && adPlayer.needPegasus;
		const pegasus = (window as any).top.pegasus || { cmd: [] };
		const lazyLoadDailymotion =
			usePegasus && instance.player.name === 'dailymotion-embed';
		const that = this;

		const pushToPegasus = () => {
			const pegasusHandler = async (responseBids: PegasusResponseBids) =>
				await playerInitWithPegasus(player, adPlayer, responseBids);

			const cbName = adPlayer._hbVideoActive
				? 'onHbVideoReady'
				: 'globalTargeted';

			pegasus.cmd.push({ disabledPreroll: pegasusHandler });
			pegasus.cmd.push({
				[cbName]: pegasusHandler
			});

			logger.info(`init pushed to pegasus on ${cbName}`)();
		};

		if (usePegasus) {
			if (lazyLoadDailymotion) {
				(instance.player as DailymotionEmbedPlayer)
					.createLazyPlayer()
					.then(pushToPegasus);
			} else {
				pushToPegasus();
			}

			setTimeout(async () => {
				const isPegasusInitialized = (pegasus && pegasus.version) || (pegasus && pegasus.VERSION);

				if (!isPegasusInitialized) {
					logger.error('Pegasus is not responding')();

					(instance.adPlayer as DailymotionEmbedAdPlayer).needPegasus = false;
					await initPlayerWithoutPegasus();
				}
			}, PEGASUS_TIMEOUT);
		} else {
			await initPlayerWithoutPegasus();
		}

		async function initPlayerWithoutPegasus() {
			logger.debug('initPlayerWithoutPegasus()')();

			await player.init(await getAdParams(adPlayer), _playerInitCb);

			logger.debug('Player initialized')();

			async function _playerInitCb(): Promise<void> {
				player.publish(PublishableVideoStickyEvents.STICKY_READY);

				if (!adPlayer) return Promise.resolve();

				await initAdPlayer(that.store, player);

				return Promise.resolve();
			}
		}

		// TODO: Type this function && type adPlayer && type responseBids
		async function playerInitWithPegasus(
			player: PlayerInstance,
			adPlayer: any,
			responseBids?: any
		) {
			logger.debug('Player Init With Pegasus')();
			const isPrerollDisabled = ((
				player as DailymotionEmbedPlayer
			).isAdDisabled = (window as any).pegasus.getData(
				'adops.disableAdPreroll'
			));

			const isResponseBids = typeof responseBids !== 'undefined';
			if (isResponseBids) {
				adPlayer.setHbResponses(responseBids);
			}

			// TODO: Type Pegasus
			const pegasusAccount = (window as any).pegasus.getData(
				'adserver.dfp.account'
			);

			const pegasusAdUnit = (window as any).pegasus.getData(
				'slots.instream.adUnitPath'
			);

			adPlayer.adUnit = '/' + pegasusAccount + '/' + pegasusAdUnit;

			let adParams: AdsParams | undefined;
			if (isPrerollDisabled) {
				_disablePreroll(player);
			} else {
				adParams = await getAdParams(adPlayer);
			}

			logger.info('Player init with Pegasus using adParams', adParams)();

			await player.init(adParams, () =>
				_onPlayerInit(isPrerollDisabled, player)
			);

			function _disablePreroll(player: PlayerInstance): void {
				logger.debug('_disablePreroll(player)', player)();
				player.cancelAdvertising();
			}

			async function _onPlayerInit(
				disablePreroll: boolean,
				player: PlayerInstance
			): Promise<void> {
				if (!adPlayer || disablePreroll) return;

				replaceVariable();
				await initAdPlayer(that.store, player);

				// Check if that deserve a real purpose
				player.publish(PublishableVideoStickyEvents.STICKY_READY);

				function replaceVariable() {
					if (
						!adPlayer.replaceVariable ||
						typeof adPlayer.replaceVariable !== 'function'
					)
						return;

					adPlayer.replaceVariable();
				}
			}
		}

		// TODO: Move that to Dailymotion adPlayer
		async function getAdParams(adPlayerInstance) {
			return adPlayerInstance
				? {
						ui: adPlayerInstance.adUnit, // TODO cast to right class (not abstract)
						custom: await adPlayerInstance.getAdTagCustomParameters()
				  }
				: undefined;
		}

		async function initAdPlayer(store: Store, player?: any): Promise<void> {
			await adPlayer.init(store, player);
			adPlayer.onApiLoaded();
			return Promise.resolve();
		}
	}

	private _logError = (message: string, category?: string) =>
		afLogger.logError(message, category);

	private _pausePreviousPlayingInstance(id: string) {
		const previousInstance = this.store.getCurrentPlayableInstance();
		if (previousInstance && id !== previousInstance.player.id) {
			if (previousInstance.adPlayer) {
				previousInstance.adPlayer.pause();
			}

			previousInstance.player.pause();
		}

		// Set current instance as the new playing instance
		this.store.setCurrentPlayableInstanceId(id);
	}

	// Create a global listener  on user scroll
	private _initScrollListener() {
		const pluginsWithScrollHandlers = [
			this.plugins.get('sticky'),
			this.plugins.get('autoplay')
		];

		function globalScrollHandler() {
			// Handlers are called in the correct order and are not debounced functions as we need to control the execution order
			// As this global handler is already using debounce we don't need to chain debounced handlers
			pluginsWithScrollHandlers.forEach(function (plugin = {}) {
				const handler = plugin.scrollEvent;
				if (handler && typeof handler === 'function') {
					handler.apply();
				}
			});
		}

		document.addEventListener(
			'scroll',
			AufVideoUtils.throttle(globalScrollHandler, 50, {
				leading: true
			}),
			{ passive: true }
		);
	}

	private _initPlugins(plugins: string[]) {
		plugins.forEach((plugin) => {
			switch (plugin) {
				case VideoPlugins.STICKY:
					{
						this.plugins.set(
							plugin.toLocaleLowerCase(),
							new StickyPlugin({
								store: this.store
							})
						);
						logger.debug('Sticky plugin loaded')();
					}
					break;

				case VideoPlugins.AUTOPLAY:
					{
						this.plugins.set(
							plugin.toLocaleLowerCase(),
							new AutoplayPlugin({
								store: this.store
							})
						);
						logger.debug('Autoplay plugin loaded')();
					}
					break;

				case VideoPlugins.ANALYTICS:
					{
						this.plugins.set(
							plugin.toLocaleLowerCase(),
							new AnalyticsPlugin({
								store: this.store
							})
						);
						logger.debug('Analytics plugin loaded')();
					}
					break;

				case VideoPlugins.DEBUG:
					{
						this.plugins.set(
							plugin.toLocaleLowerCase(),
							new DebugPlugin({
								store: this.store
							})
						);
						logger.debug('Debug plugin loaded')();
					}
					break;
			}
		});
	}

	// Related contents
	// TODO @yohanauf: move init to a separate plugin
	// TODO @damien: Check if that still deserve a purpose
	public initRelatedContents(player) {
		const RelatedContents = (window as any).AufVideo.RelatedContents;
		if (!RelatedContents) return;

		const afRelatedContent = new RelatedContents();
		afRelatedContent.init({
			id: player.id,
			player,
			labels: this.labels,
			revolver: false
		});
	}

	// TODO @damien: Check if that still deserve a purpose
	private _getInstancesAdsInfo() {
		const infos: { playerId: string; placement: string }[] = [];
		const instances = this.store.instances;

		instances.forEach((instance: StoreInstance, key: string) => {
			if (instance.adPlayer) {
				infos.push({
					playerId: instance.adPlayer.id,
					placement: instance.adPlayer.adUnit.split('/').pop()
				});
			}
		});
	}

	public static get(options?: ManagerOptions): Manager {
		if (!Manager.instance) {
			Manager.instance = new Manager(options);
		}

		return Manager.instance;
	}
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.Manager = window.AufVideo.Manager || Manager;

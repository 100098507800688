/**
 * This file responsability is to pilot the player instantiations modes
 */

import { Players } from './enums/players.enum';
import { VideoPlayerSettings } from './interfaces/VideoPlayerSettings.interface';
import { initAll } from './spawner';
import { AufVideoUtils } from './utils/utils';
import { VideoConsoleLogger } from './utils/video-logger';

const w = window as any;
const logger = new VideoConsoleLogger();

function _isNoVideoTestMode() {
	const queryParamsArr = location.search.slice(1).split('&');

	return (
		queryParamsArr.includes('utm_source=webperf') &&
		queryParamsArr.includes('utm_medium=psi') &&
		queryParamsArr.includes('utm_campaign=no-dailymotion')
	);
}

export async function start(
	playerConstructor: any,
	adPlayerConstructor: any,
	force?: boolean
) {
	w.videoPlayerUnifyInitDone =
		typeof force !== 'undefined' ? false : w.videoPlayerUnifyInitDone;

	async function ensureVideoVarsAreSet() {
		let t = 0;
		return new Promise(waitForVars);

		function waitForVars(resolve, reject) {
			if (window.videoPhpVars) {
				logger.info('Player vars available after ' + t * 25 + ' ms')();
				resolve(window.videoPhpVars);
			} else {
				t++;
				setTimeout(waitForVars.bind(this, resolve, reject), 25);
			}
		}
	}

	//Protection contre les instancations multiples du fait de plusieurs ESI dans la même page
	if (w.videoPlayerUnifyInitDone) {
		return;
	}
	w.videoPlayerUnifyInitDone = true;

	await ensureVideoVarsAreSet();

	logger.info('Starting video players', w.videoPhpVars)();

	for (let playerId in w.videoPhpVars) {
		const settings = w.videoPhpVars[playerId] as VideoPlayerSettings;
		settings.needPegasus = !AufVideoUtils.isPreview()
			? settings.needPegasus
			: false;

		// TODO: Déplacer ça dans le spawner
		var isDailymotionEmbed = settings.techno.id === Players.DAILYMOTION_EMBED;
		settings.isLazyLoadedPlayer = isDailymotionEmbed && settings.needPegasus;

		!_isNoVideoTestMode()
			? await initAll(playerConstructor, adPlayerConstructor, settings)
			: undefined;
	}
}

window.AufVideo = window.AufVideo || {};
window.AufVideo.start = start;

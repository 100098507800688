import { setChildren, mount } from 'redom';
import { getClassname } from 'src/plugins/sticky/utils/get-classname.util';
import { StickyPlaceholder } from '../../global/placeholder/placeholder.component';
import { StickyButtonsBar } from '../../global/buttons-bar/buttons-bar.component';
import { StickyBaseLayout } from '../base-layout';
import { FlyingLayoutSettings } from './flying-layout.interface';
import { from } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { StickyButtonsEvents } from 'src/plugins/sticky/enums/buttons-events.enum';
import { Visibility } from 'src/plugins/sticky/enums/visibility.enum';

export class FlyingStickyLayout extends StickyBaseLayout {
	background: StickyPlaceholder;
	buttonsBar: StickyButtonsBar;

	constructor(settings: FlyingLayoutSettings) {
		const { customClass } = settings;
		super({
			...settings,
			customClass: getClassname(['sticky-flying-layout'], customClass)
		});

		this.background = new StickyPlaceholder({
			customClass: 'flying-layout-background'
		});
		this.buttonsBar = new StickyButtonsBar({
			customClass: 'flying-layout-buttons'
		});

		setChildren(this.playerWrapper, [this.buttonsBar, this.playerElement]);
		setChildren(this.el, [this.background, this.playerWrapper]);

		this.toggleableElements = [this.background, this.buttonsBar];

		this.events$ = from(this.buttonsBar.events$);
		this.sticked$.next(false);

		this.closeBtnVisibility$.pipe(distinctUntilChanged()).subscribe((state: Visibility) => {
			this.buttonsBar.switchVisibility$.next({
				btn: StickyButtonsEvents.CLOSE,
				state
			})
		});

		this.mount();
	}

	mount = () => mount(this.parentNode, this);
}

//get element size+position
interface ElementPosition {
	left: number;
	top: number;
	right: number;
	bottom: number;
	width: number;
	height: number;
}

const getElementPosition = (o: HTMLElement): ElementPosition => {
	let left = 0;
	let top = 0;
	let right = 0;
	let bottom = 0;

	let body = document.body;
	let width = o.offsetWidth;
	let height = o.offsetHeight;

	let offSetParent = o.offsetParent;

	if (offSetParent) {
		let p: any = o;
		left = o.offsetLeft - o.scrollLeft;
		top = o.offsetTop - o.scrollTop;
		while ((p = p.offsetParent)) {
			if (!p) break;
			left += p.offsetLeft - (p != body ? p.scrollLeft : 0);
			top += p.offsetTop - (p != body ? p.scrollTop : 0);
		}
	}

	right = left + width;
	bottom = top + height;

	return { left, top, right, bottom, width, height };
};

// Get window size + position
const getWindowPosition = (): ElementPosition => {
	const w = window,
		e = document.documentElement,
		b = document.body;

	const width = w.innerWidth
		? w.innerWidth
		: e && e.clientWidth
		? e.clientWidth
		: b && b.clientWidth
		? b.clientWidth
		: 0;

	const height = w.innerHeight
		? w.innerHeight
		: e && e.clientHeight
		? e.clientHeight
		: b && b.clientHeight
		? b.clientHeight
		: 0;

	const left = w.pageXOffset
		? w.pageXOffset
		: e && e.scrollLeft
		? e.scrollLeft
		: b && b.scrollLeft
		? b.scrollLeft
		: 0;

	const top = w.pageYOffset
		? w.pageYOffset
		: e && e.scrollTop
		? e.scrollTop
		: b && b.scrollTop
		? b.scrollTop
		: 0;

	const right = left + width;
	const bottom = top + height;

	return { left, top, right, bottom, width, height };
};

/**
 * Get visibility percent of element in viewport
 */
const percentInViewport = (el: Element | null, topOffset?: number): number => {
	if (!el) {
		return 0;
	}

	var rect = el.getBoundingClientRect();
	var wSize = getWindowPosition();
	var visibleHeight = rect.height - (topOffset || 0);
	var visibleWidth = rect.width;
	var totalSize = visibleHeight * visibleWidth;

	// A part of element is above viewport
	if (rect.top < 0 && rect.bottom > 0) {
		visibleHeight += rect.top;
	}

	//  A part of element is below viewport
	if (rect.bottom > wSize.height && rect.top < wSize.height) {
		visibleHeight += wSize.height - rect.bottom;
	}

	// Element is totally above or below viewport
	if (
		Math.max(rect.top, rect.bottom) < 0 ||
		Math.min(rect.bottom, rect.top) > wSize.height
	) {
		visibleHeight = 0;
	}

	//  A part of element is on the left of viewport
	if (rect.left < 0 && rect.right > 0) {
		visibleWidth += rect.left;
	}

	//  A part of element is on the right of viewport
	if (rect.right > wSize.width && rect.left < wSize.width) {
		visibleWidth += wSize.width - rect.right;
	}

	// Element is totally on the right/left of viewport
	if (
		Math.max(rect.left, rect.right) < 0 ||
		Math.min(rect.left, rect.right) > wSize.width
	) {
		visibleWidth = 0;
	}

	return (visibleHeight * visibleWidth) / totalSize;
};

/**
 * Detect if element is in viewport
 */
const isInViewport = (
	el: HTMLElement | null,
	percent = 1,
	topOffset?: number
): boolean => percentInViewport(el, topOffset) >= percent;

export { getElementPosition, getWindowPosition, isInViewport };
